import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { Divider } from "rsuite";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";

function AdminSettings() {
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const authContext = useAuth();
    const expectedGuestsSettingName = "expectedGuests";
    const enableGallerySettingName = "enableGalleryBrowse";
    const tableauWpxSettingName = "tableauWpx";
    const tableauYpxSettingName = "tableauYpx";
    const [expectedGuest, setExptectedGuest] = useState<number>(0);
    const [tableauWpx, setTableauWpx] = useState<number>(0);
    const [tableauYpx, setTableauYpx] = useState<number>(0);
    const [enableGallery, setEnableGallery] = useState<number>(0);


    useEffect(() => {
        showLoader();
        setSectionName("Impostazioni");
        fetchSetting(expectedGuestsSettingName);
        fetchSetting(enableGallerySettingName);
    }, [])

    const fetchSetting = (name: string) => {
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}`}
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/settings/${name}`, config)
            .then(response => {
                if (response.data) {
                    if (name === expectedGuestsSettingName) { setExptectedGuest(response.data); }
                    if (name === enableGallerySettingName) { setEnableGallery(response.data); }
                }
                hideLoader();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const saveSetting = (name: string, value: string) => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/settings/${name}`, { 'name': name, 'value': value }, config)
            .then(response => {               
                hideLoader();
                showAlert("", <>Operazione eseguita correttamente</>, "success");
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const SettingField = ({ settingName, label, val, setter }:any) => {
        return <>
            <Row>
                <Form.Group as={Col} xs="6" md="6" lg="4" controlId={`setting-${settingName}`}>
                    <FloatingLabel controlId={`floatingName${settingName}`} label={label}>
                        <Form.Control type="text" placeholder={label} onChange={(event: any) => { setter(event.target.value); }} value={val} />
                    </FloatingLabel>
                </Form.Group>
                <Col>
                    <Button type="button" style={{marginTop: "12px"}} onClick={() => { saveSetting(settingName, val.toString()) }} className="t-primary-button">Salva</Button>
                </Col>
            </Row>
            <Divider></Divider>
        </>
    }

    return (
        <Container fluid className="admin-main-content">            
            <SettingField settingName={expectedGuestsSettingName} label="Invitati Attesi" val={expectedGuest} setter={setExptectedGuest}  />
            <SettingField settingName={enableGallerySettingName} label="Abilita Gallery" val={enableGallery} setter={setEnableGallery}  />
            {/*<SettingField settingName={tableauWpxSettingName} label="Tableau Wpx" val={tableauWpx} setter={ setTableauWpx}  />*/}
            {/*<SettingField settingName={tableauYpxSettingName} label="Tableau Ypx" val={tableauYpx} setter={ setTableauYpx}  />*/}
        </Container>
    );
}

export default AdminSettings;