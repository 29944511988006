import { useAuth } from "./AuthProvider";
import { Col, Container, Row } from 'react-bootstrap';
import { useEffect } from "react";

function Logout() {
    const authProvider = useAuth();
    
    useEffect(() => {
        authProvider.logOut();
    },[])
  return (
      <div className="wrapper t-guest-data">
          <Container fluid className="t-bg-first header">
              <Container>
                  <Row>
                      <Col sm={4} md={4} lg={4}>
                          <div className="t-logo">
                              <div>{process.env.REACT_APP_HIM_NAME}</div>
                              <div>&</div>
                              <div>{process.env.REACT_APP_HER_NAME}</div>
                          </div>
                      </Col>
                      <Col className="title">
                          <div>A presto!</div>
                          <div>Logout effettuato</div>
                      </Col>
                  </Row>
              </Container>
          </Container>
      </div>
  );
}

export default Logout;