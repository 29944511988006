import { useEffect, useState, useRef, useReducer } from 'react';
import { useWizardContext, WizardContextReducerAction} from './publicWizard'
import { ParticipantListItem, ParticipantAge, ParticipantAllergy, ParticipantUnfavorability, ParticipantMenuChoice } from '../../../common/types/Participant'
import { Button, Container, Row, Col, Form, FloatingLabel, Anchor } from 'react-bootstrap'
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import { useNavigate } from 'react-router-dom';
import PublicLoader, { ILoaderRef } from './publicLoaderComponent';
import axios from 'axios';
import { useAuth } from "../../../common/components/ts/AuthProvider";
import AlertComponent, { IAlertRef } from '../../../common/components/ts/Alert';
export default function PublicWizardNewGuestData() {
    const authContext = useAuth();
    const [wizardContext, dispatchWizardContext] = useWizardContext();
    const [stepper, setStepper] = useState<Stepper>();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [currentDataStep, setCurrentDataStep] = useState<number>(1);
    const [sendData, setSendData] = useState(false);
    const navigate = useNavigate();
    let loaderRef = useRef<ILoaderRef>();
    let alertRef = useRef<IAlertRef>();
    const participantExists = (participant: ParticipantListItem, indexNumber: number, callback: any) => {
        let contextFiltered = wizardContext?.listOfGuests?.filter((item, index) => {
            return indexNumber != index
                && item.name?.trim() === participant?.name?.trim()
                && item.surname?.trim() === participant?.surname?.trim()
                && item.age === participant?.age
        });
        if (contextFiltered !== undefined && contextFiltered.length > 0) {
            alertRef?.current?.show("danger", "Oh no!", <><div>{participant.name} {participant.surname} &egrave; gi&agrave; presente nell'elenco dei partecipanti!</div></>);
            loaderRef?.current?.hide();
            callback(true);
            return;
        }

        let config = {
            params: {
                name: participant.name,
                surname: participant.surname,
                age: participant.age
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/publicarea/guests/exists", config)
            .then(response => {
                loaderRef?.current?.hide();
                if (response.data) {
                    alertRef?.current?.show("danger", "Oh no!", <><div>{participant.name} {participant.surname} &egrave; gi&agrave; presente nell'elenco dei partecipanti!</div></>);
                }
                callback(response.data);
                return;
            })
            .catch(error => {
                loaderRef?.current?.hide();
                alertRef?.current?.show("danger", "Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                callback(true);
                return;
            });
    }

    const updateParticipant = (participant: ParticipantListItem, index: number) => {
        console.log(participant);
        dispatchWizardContext({
            type: WizardContextReducerAction.CHANGE_PARTICIPANT,
            participantIndex: index,
            nextParticipant: participant
        });
    }

    const updateAllergies = (allergies: ParticipantAllergy[], index: number) => {
        dispatchWizardContext({
            type: WizardContextReducerAction.CHANGE_ALLERGY,
            participantIndex: index,
            nextAllergies: allergies
        });
    }


    const updateOtherAllergy = (allergy: string, index: number) => {
        dispatchWizardContext({
            type: WizardContextReducerAction.CHANGE_OTHER_ALLERGY,
            participantIndex: index,
            nextOtherAllergy: allergy
        });
    }

    const updateUnfavor = (unfavor: ParticipantUnfavorability[], index: number) => {
        dispatchWizardContext({
            type: WizardContextReducerAction.CHANGE_UNFAVOR,
            participantIndex: index,
            nextUnfavorability: unfavor
        });
    }

    const updateMenuChoice = (choice: ParticipantMenuChoice, index: number) => {
        dispatchWizardContext({
            type: WizardContextReducerAction.CHANGE_MENU_CHOICE,
            participantIndex: index,
            nextMenuChoice: choice
        });
    }

    const goToNextStep = (nextStep: number) => {
        setSendData(false);
        if (nextStep === 3) {
            if (currentStep === wizardContext.guestsNumber) {
                setSendData(true);
                setCurrentDataStep(nextStep);                
            } else {                
                stepper?.next()
                setCurrentDataStep(1);
            }
        } else {
            setCurrentDataStep(nextStep);
        }        
        loaderRef?.current?.hide();
    }

    useEffect(() => {
        const stepperDiv = document.querySelector('#stepper')
        setStepper(new Stepper(stepperDiv!, {
            linear: false,
            animation: true
        }))
        stepperDiv?.addEventListener('shown.bs-stepper', function (event: any) {
            setCurrentStep(event.detail.indexStep + 1);
            document.querySelectorAll('.bs-stepper-circle').forEach(e => e?.classList.remove('done'));
            if (event.detail.indexStep > 0) {
                for (let j = 0; j < event.detail.indexStep; j++) {
                    document.querySelector(`[data-target='#invitato-${j}'] .bs-stepper-circle`)?.classList.add("done")
                }
            }
        })

    }, []);

    return (

        <div className="wrapper t-guest-data">
            <PublicLoader ref={loaderRef}></PublicLoader>
            <AlertComponent ref={alertRef}></AlertComponent>
            <Container fluid className="t-bg-first header">
                <Container>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <div className="t-logo">
                                <div>Francesco</div>
                                <div>&</div>
                                <div>Marica</div>
                            </div>
                        </Col>
                        <Col className="title">
                            <div>Dati Invitati</div>
                            <div>Inserisci i dati dell'invitato n. {currentStep}</div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="t-bg-third t-stepper">
                <Container>
                    <Row className="t-back-home-a">
                        <Col>
                            <Anchor className="t-link" onClick={() => { navigate("/p") }}>Torna alla home</Anchor>
                        </Col>
                    </Row>
                    <Row>
                        <div id="stepper" className="bs-stepper">
                            <div className="bs-stepper-header" role="tablist">
                                {(() => {
                                    const steps = [];

                                    for (let guestIndex = 0; guestIndex < wizardContext.guestsNumber; guestIndex++) {

                                        steps.push(<div key={`bs-step-div-${guestIndex}`} className="step" data-target={`#invitato-${guestIndex}`}>
                                            <button type="button" className={`step-trigger ${wizardContext?.listOfGuests?.[guestIndex]?.name !== undefined ? "filled" : ""}`} role="tab" aria-controls={`invitato-${guestIndex}`} id={`invitato-${guestIndex}-trigger`}>
                                                <span className="bs-stepper-circle">{guestIndex + 1}</span>
                                            </button>
                                            <div>{wizardContext?.listOfGuests?.[guestIndex]?.name}</div>
                                            <div>{wizardContext?.listOfGuests?.[guestIndex]?.surname}</div>
                                            <div>{wizardContext?.listOfGuests?.[guestIndex]?.age != null ? wizardContext?.listOfGuests?.[guestIndex]?.age === ParticipantAge.Adulto ? "Adulto" : "Bambino" : ""} </div>
                                        </div>

                                        )

                                        if (guestIndex < wizardContext.guestsNumber - 1) {
                                            steps.push(<div key={`bs-step-line-${guestIndex}`} className="line"></div>);
                                        }
                                    }

                                    return steps;
                                })()}
                            </div>
                            <div className="bs-stepper-content">
                                {(() => {
                                    const stepsContent = [];
                                    for (let guestIndex = 0; guestIndex < wizardContext.guestsNumber; guestIndex++) {
                                        stepsContent.push(
                                            <div id={`invitato-${guestIndex}`} className="content fade" role="tabpanel" aria-labelledby={`invitato-${guestIndex}-trigger`}>
                                                <div id={`details-${guestIndex}`} className="t-participant-data">
                                                    <ParticipantMainData participantProp={wizardContext?.listOfGuests?.[guestIndex]}
                                                        participantIndex={guestIndex}
                                                        updateParticiapnt={updateParticipant}
                                                        currentDataStep={currentDataStep}
                                                        stepperBack={() => { stepper?.previous(); }}
                                                        nextStep={goToNextStep}
                                                        loaderRef={loaderRef}
                                                        participantExists={participantExists}
                                                    />
                                                    <ParticipantMenuData participantProp={wizardContext?.listOfGuests?.[guestIndex]}
                                                        participantIndex={guestIndex}
                                                        updateAllergies={updateAllergies}
                                                        updateOtherAllergy={updateOtherAllergy}
                                                        updateUnfavor={updateUnfavor}
                                                        currentDataStep={currentDataStep}
                                                        nextStep={goToNextStep}
                                                        updateMenuChoice={updateMenuChoice}
                                                        loaderRef={loaderRef}
                                                    />                                                   
                                                </div>
                                            </div>
                                        );
                                    }

                                    return stepsContent;
                                })()}
                                <SendData guestList={wizardContext?.listOfGuests}
                                    navigate={navigate}
                                    authContext={authContext}
                                    loaderRef={loaderRef}
                                    alertRef={alertRef}
                                    currentDataStep={currentDataStep}
                                    nextStep={goToNextStep}
                                    sendData={sendData}
                                    setSendData={setSendData}
                                />
                            </div>
                        </div>
                    </Row>
                </Container>
            </Container>
        </div>
    )

}

function ParticipantMainData({ participantProp, updateParticiapnt, participantIndex, currentDataStep, stepperBack, nextStep, loaderRef, participantExists }: any) {
    const [validated, setValidated] = useState(false);
    const [participant, setParticipant] = useState<ParticipantListItem>({ ...participantProp });

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        loaderRef?.current.show();
        const form = event.currentTarget;
        let formValid = form.checkValidity();        
        setValidated(true);
        if (formValid) {
            participantExists(participant, participantIndex, (result: boolean) => {
                if (!result) {
                    updateParticiapnt(participant, participantIndex);
                    nextStep(2);
                }
            })
        }
        else {
            loaderRef?.current.hide();
        }
    };

    const handleInputNameChange = (event: any) => {
        if (participant !== undefined) {
            setParticipant({ ...participant, name: event.target.value });
        }
    };
    const handleInputSurnameChange = (event: any) => {
        if (participant !== undefined) {
            setParticipant({ ...participant, surname: event.target.value });
        }
    };

    const handleInputAgeChange = (event: any) => {
        if (participant !== undefined) {
            if (event.target.getAttribute("data-age") === "adulto") {
                setParticipant({ ...participant, age: ParticipantAge.Adulto });
            }
            if (event.target.getAttribute("data-age") === "bambino") {
                setParticipant({ ...participant, age: ParticipantAge.Bambino });
            }
        }
    };

    return currentDataStep !== 1 ? <></> :
        <>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group as={Col} controlId={`validationName${participantIndex.toString()}`}>
                            <FloatingLabel controlId={`floatingName${participantIndex.toString()}`} label="Nome">
                                <Form.Control type="text" placeholder="Nome" required onChange={handleInputNameChange} value={participant.name!} />
                                <Form.Control.Feedback type="invalid">
                                    Nome obbligatorio
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group as={Col} className="form-label-group" controlId={`validationSurname${participantIndex.toString()}`}>
                            <FloatingLabel controlId={`floatingSurname${participantIndex.toString()}`} label="Cognome">
                                <Form.Control type="text" placeholder="Cognome" required onChange={handleInputSurnameChange} value={participant.surname!} />
                                <Form.Control.Feedback type="invalid">
                                    Cognome obbligatorio
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col className="t-radio-age">
                        <Form.Check type="radio" id="radio-adulto" data-index={participantIndex} >
                            <Form.Check.Input type="radio" required name={`group${participantIndex.toString()}`} onChange={handleInputAgeChange} data-age="adulto" checked={participant.age === ParticipantAge.Adulto} />
                            <Form.Check.Label>Adulto</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                Selezionare un valore
                            </Form.Control.Feedback>
                        </Form.Check>
                        <Form.Check type="radio" id="radio-bambino" data-index={participantIndex} >
                            <Form.Check.Input type="radio" required name={`group${participantIndex.toString()}`} onChange={handleInputAgeChange} data-age="bambino" checked={participant.age === ParticipantAge.Bambino} />
                            <Form.Check.Label>Bambino</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                &nbsp;
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Col>
                </Row>
                <Row className="button">
                    {(() => {
                        if (participantIndex > 0) {
                            return (
                                <Col>
                                    <Button className="t-primary-button" onClick={() => { stepperBack(); }}>Indietro</Button>
                                </Col>);
                        }
                    })()}

                    <Col>
                        <Button type="submit" className="t-primary-button">Avanti</Button>
                    </Col>
                </Row>
            </Form>
        </>


}

function ParticipantMenuData({ participantProp, updateAllergies, updateOtherAllergy, updateUnfavor, participantIndex, currentDataStep, nextStep, updateMenuChoice, loaderRef }: any) {
    const allergies = ["Glutine", "Crostacei", "Molluschi", "Frutta secca", "Soia e derivati", "Uova", "Lattosio", "Senape", "Sesamo", "Sedano", "Lupini"];
    const unfavored = ["Pesce Cotto", "Pesce Crudo", "Carne", "Latticini e Formaggi", "Ogni derivato animale"];
    const [participantAllergies, setParticipantAllergies] = useState<ParticipantAllergy[]>(participantProp === undefined || participantProp.allergies === undefined ? [] : [...participantProp.allergies]);
    const [participantUnfavor, setParticipantUnfavor] = useState<ParticipantUnfavorability[]>(participantProp === undefined || participantProp.unfavorability === undefined ? [] : [...participantProp.unfavorability]);

    const handleSubmit = (event: any) => {
        loaderRef?.current.show();
        const form = event.currentTarget;
        let isValid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();
        if (isValid) {
            updateAllergies(participantAllergies, participantIndex);
            updateUnfavor(participantUnfavor, participantIndex);
            nextStep(3);
        }
        else {
            loaderRef?.current.hide();
        }
    };

    const handleAllergySwitchChange = (event: any) => {
        let itemName = event.target.getAttribute("data-item");
        if (participantAllergies === undefined) {
            setParticipantAllergies([{ allergy: itemName, hasAllergy: event.target.checked }]);
        }
        else {
            let propIndex = participantAllergies.findIndex(x => x.allergy === itemName);
            if (propIndex < 0) {
                setParticipantAllergies(participantAllergies => [...participantAllergies, { allergy: itemName, hasAllergy: event.target.checked }]);
            }
            else {
                let prevParticipantAllergies = [...participantAllergies];
                prevParticipantAllergies[propIndex].hasAllergy = event.target.checked;
                setParticipantAllergies([...prevParticipantAllergies])
            }
        }
        //updateAllergies(participantAllergies, participantIndex);
    }

    const handleUnfavorSwitchChange = (event: any) => {
        let itemName = event.target.getAttribute("data-item");
        if (participantUnfavor === undefined) {
            setParticipantUnfavor([{ unfavorability: itemName, hasUnfavorability: event.target.checked }]);
        }
        else {
            let propIndex = participantUnfavor.findIndex(x => x.unfavorability === itemName);
            if (propIndex < 0) {
                setParticipantUnfavor(participantUnfavor => [...participantUnfavor, { unfavorability: itemName, hasUnfavorability: event.target.checked }]);
            }
            else {
                let prevParticipantUnfavor = [...participantUnfavor];
                prevParticipantUnfavor[propIndex].hasUnfavorability = event.target.checked;
                setParticipantUnfavor([...participantUnfavor])
            }
        }

        //updateUnfavor(participantUnfavor, participantIndex);
    }

    const handleInputMenuChoiceChange = (event: any) => {
        updateOtherAllergy("", participantIndex);
        updateMenuChoice(Number.parseInt(event.target.getAttribute("data-choice")), participantIndex);
    };

    useEffect(() => {

        for (let itemName of allergies) {
            let propIndex = participantAllergies.findIndex(x => x.allergy === itemName);
            if (propIndex < 0) {
                setParticipantAllergies(participantAllergies => [...participantAllergies, { allergy: itemName, hasAllergy: false }]);
            }
        }

        for (let itemName of unfavored) {
            let propIndex = participantUnfavor.findIndex(x => x.unfavorability === itemName);
            if (propIndex < 0) {
                setParticipantUnfavor(participantUnfavor => [...participantUnfavor, { unfavorability: itemName, hasUnfavorability: false }]);
            }
        }
    }, [])

    return (
        currentDataStep !== 2 ? <></> :
            participantProp?.age === ParticipantAge.Adulto || (participantProp?.age === ParticipantAge.Bambino && participantProp?.menuChoice === ParticipantMenuChoice.ADULTO) ?
                <>
                    <Form className="t-allergies-form" onSubmit={handleSubmit}>
                        {(() => {
                            if (participantProp?.age === ParticipantAge.Bambino) {
                                return <Anchor className="t-link" onClick={() => {
                                    updateMenuChoice(ParticipantMenuChoice.BAMBINO, participantIndex);
                                    setParticipantAllergies([]);
                                    setParticipantUnfavor([]);
                                    updateAllergies([], participantIndex);
                                    updateUnfavor([], participantIndex);
                                    updateOtherAllergy("", participantIndex);
                                }}>Torna al menu bimbi</Anchor>;
                            }
                        })()}
                        <Row className="title">
                            <Col>
                                <div>Preferenze alimentari</div>
                            </Col>
                        </Row>
                        <Row className="subtitle">
                            <Col>
                                <div>Seleziona eventuali allergie o intolleranze a qualcuno di questi alimenti</div>
                            </Col>
                        </Row>
                        {(() => {
                            const allergiesSwitch = [];
                            for (let allergy of allergies) {
                                allergiesSwitch.push(
                                    <Row>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id={`allergy-switch${allergy.toString()}-${participantIndex.toString()}`}
                                                key={`allergy-switch${allergy.toString()}-${participantIndex.toString()}`}
                                                label={allergy}
                                                data-item={allergy}
                                                onChange={handleAllergySwitchChange}
                                                checked={participantAllergies !== null && participantAllergies !== undefined
                                                    && participantAllergies !== undefined
                                                    && participantAllergies.findIndex(x => x.allergy === allergy) >= 0
                                                    && participantAllergies[participantAllergies.findIndex(x => x.allergy === allergy)].hasAllergy === true
                                                }
                                            />
                                        </Col>
                                    </Row>
                                );
                            }
                            return allergiesSwitch;
                        })()}
                        <Row>
                            <Col>
                                <Form.Group key={`allergy-tarea-${participantIndex.toString()}`} className="mb-3" controlId={`allergy-tarea-${participantIndex.toString()}`}>
                                    <Form.Label>Altro / Specifiche</Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={(event: any) => { updateOtherAllergy(event.target.value, participantIndex); }} value={participantProp !== undefined ? participantProp.otherAllergy : ""} />
                                </Form.Group>
                            </Col></Row>
                        <Row className="subtitle">
                            <Col>
                                <div>Seleziona eventuali alimenti non graditi</div>
                            </Col>
                        </Row>
                        {(() => {
                            const allergiesSwitch = [];
                            for (let unfavor of unfavored) {
                                allergiesSwitch.push(
                                    <Row>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id={`allergy-switch${unfavor.toString()}-${participantIndex.toString()}`}
                                                key={`allergy-switch${unfavor.toString()}-${participantIndex.toString()}`}
                                                label={unfavor}
                                                data-item={unfavor}
                                                onChange={handleUnfavorSwitchChange}
                                                checked={participantUnfavor !== null && participantUnfavor !== undefined
                                                    && participantUnfavor !== undefined
                                                    && participantUnfavor.findIndex(x => x.unfavorability === unfavor) >= 0
                                                    && participantUnfavor[participantUnfavor.findIndex(x => x.unfavorability === unfavor)].hasUnfavorability === true
                                                }
                                            />
                                        </Col>
                                    </Row>
                                );
                            }
                            return allergiesSwitch;
                        })()}
                        <Row className="button">

                            <Col>
                                <Button className="t-alternate-button" onClickCapture={() => { nextStep(1); }}>Indietro</Button>
                            </Col>


                            <Col>
                                <Button type="submit" className="t-primary-button">Avanti</Button>
                            </Col>
                        </Row>
                    </Form>
                </>
                :
                <>
                    <Form className="t-allergies-form" onSubmit={handleSubmit}>
                        <Row className="title">
                            <Col>
                                <div>Preferenze alimentari</div>
                            </Col>
                        </Row>
                        <Row className="subtitle">
                            <Col>
                                <div>Il men&ugrave; Bimbi &egrave; composto da</div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <ul>
                                    <li>Prosciutto Cotto/Crudo, Mozzarella</li>
                                    <li>Pasta al sugo (Pennette/Orecchiette)</li>
                                    <li>Carne di pollo (Birbe/Cotoletta/Fettine)</li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className="subtitle">
                            <Col>
                                <div>Esprimi una preferenza</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="t-radio-choice">
                                <Form.Check type="radio" id="radio-mc-bambino" data-index={participantIndex} >
                                    <Form.Check.Input type="radio" required name={`group-mc-${participantIndex.toString()}`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.BAMBINO} checked={participantProp?.menuChoice === ParticipantMenuChoice.BAMBINO} />
                                    <Form.Check.Label>Va bene il men&ugrave; bimbi</Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Selezionare un valore
                                    </Form.Control.Feedback>
                                </Form.Check>
                                <Form.Check type="radio" id="radio-mc-adulto" data-index={participantIndex} >
                                    <Form.Check.Input type="radio" required name={`group-mc-${participantIndex.toString()}`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.ADULTO} checked={participantProp?.menuChoice === ParticipantMenuChoice.ADULTO} />
                                    <Form.Check.Label>Preferisco il men&ugrave; adulti</Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">
                                        &nbsp;
                                    </Form.Control.Feedback>
                                </Form.Check>
                                <Form.Check type="radio" id="radio-mc-bambino-mod" data-index={participantIndex} >
                                    <Form.Check.Input type="radio" required name={`group-mc-${participantIndex.toString()}`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.BAMBINO_MOD} checked={participantProp?.menuChoice === ParticipantMenuChoice.BAMBINO_MOD} />
                                    <Form.Check.Label>Va bene il men&ugrave; bimbi ma con queste modifiche</Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">
                                        &nbsp;
                                    </Form.Control.Feedback>
                                </Form.Check>
                            </Col>
                        </Row>
                        {(() => {
                            if (participantProp?.menuChoice === ParticipantMenuChoice.BAMBINO_MOD) {
                                return <Row>
                                    <Col>
                                        <Form.Group key={`allergy-tarea-${participantIndex.toString()}`} className="mb-3" controlId={`allergy-tarea-${participantIndex.toString()}`}>
                                            <Form.Label>Altro / Specifiche</Form.Label>
                                            <Form.Control as="textarea" rows={3} onChange={(event: any) => { updateOtherAllergy(event.target.value, participantIndex); }} value={participantProp !== undefined ? participantProp.otherAllergy : ""} />
                                        </Form.Group>
                                    </Col></Row>
                                    ;
                            }
                        })()}
                        <Row className="button">

                            <Col>
                                <Button className="t-alternate-button" onClickCapture={() => { nextStep(1); }}>Indietro</Button>
                            </Col>


                            <Col>
                                <Button type="submit" className="t-primary-button">Avanti</Button>
                            </Col>
                        </Row>
                    </Form>
                </>
    );
}

function SendData({ guestList, navigate, authContext, loaderRef, alertRef, currentDataStep, nextStep, sendData, setSendData}: any) {
    return currentDataStep === 3 && sendData ?
        <>
            {(() => {   
                document.querySelector(`.bs-stepper-circle`)?.classList.add("done")
                loaderRef?.current?.show();
                let config = {
                    headers: { Authorization: `Bearer ${authContext.token}` }
                }
                const client = axios.create({
                    baseURL: process.env.REACT_APP_API_BASE_URL
                });
                client.post("/api/publicarea/guests", guestList, config)
                    .then(response => {
                        console.log(response);
                        loaderRef?.current?.hide();
                        navigate(`/p/thankyou?r=${response.data}`);
                        return;
                    })
                    .catch(error => {
                        loaderRef?.current?.hide();
                        alertRef?.current?.show("danger", "Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                        nextStep(2);
                        return;
                    });
            })()}
        </>
        :<></>
}