import axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { renderToString } from "react-dom/server";
import { JsxElement } from "typescript";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { ParticipantAge, GuestListItem } from "../../common/types/Participant";
import { useSectionNameContext } from "./adminApp";
import { Table, Divider, SelectPicker, Modal as RModal } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { ItemDataType } from "rsuite/esm/MultiCascadeTree";

const { Column, HeaderCell, Cell } = Table;

type TableauModel = {
    id: number | null,
    name: string,
    posX: number,
    posY: number,
    guests: GuestListItem[]
}

function AdminTableauManage() {
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const [tables, setTables] = useState<TableauModel[]>([]);
    const [showInsertTable, setShowInsertTable] = useState(false);
    const [showEditTable, setShowEditTable] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [toInsertCoordinates, setToInsertCoordinates] = useState<any>({})
    const [toInsertTableName, setToInsertTableName] = useState<string>("")
    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [currentTable, setCurrentTable] = useState<TableauModel>();
    const [noTableGuests, setNoTableGuests] = useState<ItemDataType[]>([]);
    const [selectedGuesId, setSelectedGuestId] = useState<number>(0);
    const authContext = useAuth();
    const newTable = (e: any) => {
        if (!editEnabled) {
            return;
        }
        if (e.target.tagName === "SPAN") {
            return;
        }
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.       
        setShowInsertTable(true);
        setToInsertCoordinates([x, y]);
    }

    const editTable = (e: any) => {
        if (e.target.tagName !== "SPAN") {
            return;
        }
        fetchTable(parseInt(e.target.getAttribute("data-tableid")));
        setShowEditTable(true);
        fetchNotAssociated();
    }

    const handleInsertTableHide = () => {
        setShowInsertTable(false);
    }
    const handleInputNameChange = (event: any) => {
        setToInsertTableName(event.target.value);
    };
    const handleInsertTableConfirm = () => {
        setShowInsertTable(false);
        setToInsertTableName("");
        addTable({ id: 0, name: toInsertTableName, posX: toInsertCoordinates[0], posY: toInsertCoordinates[1], guests: [] });
    }


    const handleEditTableHide = () => {
        setShowEditTable(false);
        setCurrentTable(undefined);
        setSelectedGuestId(0);
    }

    const handleRemoveHide = () => {        
        setShowRemoveModal(false);
    }

    const handleRemoveConfirm = () => {
        removeTable();
    }

    const fetchTables = () => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/tableau/tables`, config)
            .then(response => {
                if (response.data) {
                    setTables(response.data);
                }
                hideLoader();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const fetchNotAssociated = () => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/tableau/tables/guests/notassociated`, config)
            .then(response => {
                if (response.data) {
                    setNoTableGuests(response.data.map((item: GuestListItem) => ({ label: `${item.name?.toUpperCase()} ${item.surname?.toUpperCase()} ${ParticipantAge[item.age ?? 0].toString().toUpperCase()}`, value: item.id })));
                }
                hideLoader();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const fetchTable = (id: number | undefined | null) => {
        if (id === undefined || id === null)
            return;
        showLoader();
        let config = {
            params: {
                includeGuests: true
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/tableau/tables/${id}`, config)
            .then(response => {
                if (response.data) {
                    console.log(response.data);
                    setCurrentTable(response.data[0]);
                }
                hideLoader();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const addTable = (table: TableauModel) => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/tableau/tables`, table, config)
            .then(response => {
                hideLoader();
                fetchTables();
                showAlert("", <>Operazione eseguita correttamente</>, "success");
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const removeGuest = (guestId: number) => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.delete(`/api/tableau/tables/${currentTable?.id}/guests/${guestId}`, config)
            .then(response => {
                hideLoader();
                fetchTable(currentTable?.id);
                fetchNotAssociated();
                showAlert("", <>Operazione eseguita correttamente</>, "success");
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const removeTable = () => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.delete(`/api/tableau/tables/${currentTable?.id}`, config)
            .then(response => {
                hideLoader();
                fetchTables();
                fetchNotAssociated();
                setShowRemoveModal(false);
                setShowEditTable(false);
                showAlert("", <>Operazione eseguita correttamente</>, "success");
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const addGuest = (guestId: number) => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/tableau/tables/${currentTable?.id}/guests`, [guestId], config)
            .then(response => {
                hideLoader();
                fetchTable(currentTable?.id);
                fetchNotAssociated();
                setSelectedGuestId(0);
                showAlert("", <>Operazione eseguita correttamente</>, "success");
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    useEffect(() => {
        setSectionName("Tableau");
        fetchTables();
        fetchNotAssociated();
    }, [])


    const ActionCell = ({ rowData, dataKey, onClick, ...props }: any) => {
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                <Button
                    variant="link"
                    onClick={() => {
                        removeGuest(rowData.id)
                    }}
                >
                    Rimuovi
                </Button>
            </Cell>
        );
    };
    const MenuCell = ({ rowData, dataKey, onClick, ...props }: any) => {
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                {rowData.age === ParticipantAge.Adulto ? 'ADULTO' : 'BAMBINO'}
            </Cell>
        );
    };
 
    return (
        <>
            <Modal show={showInsertTable} onHide={handleInsertTableHide} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Inserisci Tavolo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Col} controlId={`validationName`}>
                        <FloatingLabel controlId={`floatingName`} label="Nome Tavolo">
                            <Form.Control type="text" placeholder="Nome Tavolo" required onChange={handleInputNameChange} />
                            <Form.Control.Feedback type="invalid">
                                Nome obbligatorio
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="t-alternate-button" onClick={handleInsertTableHide}>
                        Annulla
                    </Button>
                    <Button className="t-primary-button" onClick={handleInsertTableConfirm} >Inserisci</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRemoveModal} onHide={handleRemoveHide} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Attenzione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(() => {                        
                        return <p>Confermare la rimozione del tavolo <strong>{currentTable?.name.toUpperCase()}</strong>? L'operazione non pu&ograve; essere annullata</p>;
                    })()}

                </Modal.Body>
                <Modal.Footer>
                    <Button className="t-alternate-button" onClick={handleRemoveHide}>
                        Annulla
                    </Button>
                    <Button className="t-primary-button danger" onClick={handleRemoveConfirm} >Elimina</Button>
                </Modal.Footer>
            </Modal>
            <RModal open={showEditTable} onClose={handleEditTableHide} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter">
                <RModal.Header closeButton>
                    <RModal.Title>Gestisci Tavolo: { currentTable?.name}</RModal.Title>
                </RModal.Header>
                <RModal.Body>
                    <Row>
                        <Col>
                            <SelectPicker data={noTableGuests} onSelect={(value: number) => { setSelectedGuestId(value); }} style={{ width: 300 }}></SelectPicker>
                        </Col>
                        <Col>
                            <Button className="t-primary-button" onClick={() => { addGuest(selectedGuesId); }} >Aggiungi</Button>
                        </Col>
                        {(() => {
                            if (editEnabled) {
                                return <Col>
                                    <Button className="t-primary-button danger" onClick={() => { setShowRemoveModal(true); }} >Elimina Tavolo</Button>
                                </Col>
                            }
                        })() }
                        
                    </Row>
                    <Row>
                        <Col>
                            <Divider></Divider>
                            <Table height={420} data={currentTable?.guests}
                                autoHeight={true}
                                affixHorizontalScrollbar
                            >
                                <Column flexGrow={1} minWidth={150}>
                                    <HeaderCell>Nome</HeaderCell>
                                    <Cell dataKey="name" />
                                </Column>
                                <Column flexGrow={1} minWidth={150}>
                                    <HeaderCell>Cognome</HeaderCell>
                                    <Cell dataKey="surname" />
                                </Column>
                                <Column flexGrow={1} minWidth={150}>
                                    <HeaderCell>Menu</HeaderCell>
                                    <MenuCell dataKey="age" />
                                </Column>
                                <Column flexGrow={1} minWidth={150} >
                                    <HeaderCell>&nbsp;</HeaderCell>
                                    <ActionCell dataKey="id" />
                                </Column>

                            </Table>
                        </Col>
                    </Row>

                </RModal.Body>
                <RModal.Footer>
                </RModal.Footer>
            </RModal>

            <Container fluid className="admin-main-content">
                <Row>

                    <Col>
                        <Form.Check
                            type="switch"
                            id={`editSwitch`}
                            key={`editSwitch`}
                            label="Abilita Modifica"
                            onChange={() => setEditEnabled(!editEnabled)}
                            checked={editEnabled}
                        />
                    </Col>
                    {/*<Col>*/}
                    {/*    <Button className="t-primary-button" disabled={!editEnabled} onClick={handleInsertTableConfirm} >Salva</Button>*/}
                    {/*</Col>*/}
                </Row>
            </Container>
            <div id="tableSvg" onClick={newTable} className='tableauSvg' style={{ width: "1500px", height: "2000px" }}>
                {(() => {
                    const items = []
                    for (let child of tables) {
                        items.push(<span className='tableauSvgLabel' onClick={editTable} data-tableid={child.id} style={{ position: "absolute", left: child.posX - (5 * child.name.length) - 6, top: child.posY - 12 - 6 }}>{child.name}</span>);
                    }
                    return items;
                })()}
            </div>
        </>
    );
}

export default AdminTableauManage;