import * as React from 'react';
import './App.css';
import LandingPage from './publicarea/components/js/landingPage';
import { Routes, Route, Router } from "react-router-dom"
import AdminApp from './adminarea/ts/adminApp';
import PublicWizard from './publicarea/components/js/publicWizard';
import PublicHome from './publicarea/components/js/publicHome';
import PublicAuth from './publicarea/components/js/publicAuth';
import AuthProvider from './common/components/ts/AuthProvider';
import PublicLogin from './publicarea/components/js/publicLogin'
import Unauthorized from './common/components/ts/Unauthorized';
import PublicWizardNewGuestData from './publicarea/components/js/publicWizard_newGuestData';
import PublicWizardThankYou from './publicarea/components/js/publicWizard_thankYou';
import AdminDashboard from './adminarea/ts/adminDashboard';
import AdminGuestList from './adminarea/ts/AdminGuestList';
import OauthCallback from './common/components/ts/OauthCallback';
import Logout from './common/components/ts/Logout';
import AdminGuestData from './adminarea/ts/AdminGuestData';
import AdminSettings from './adminarea/ts/AdminSettings';
import AdminDatabaseManage from './adminarea/ts/AdminDatabaseManage';
import AdminTableauManage from './adminarea/ts/AdminTableauManage';
import AdminTableauList from './adminarea/ts/AdminTableauList';
import PublicGallery from './publicarea/components/js/PublicGallery';
import AdminGalleryList from './adminarea/ts/AdminGalleryList';
import AdminGalleryDetail from './adminarea/ts/AdminGalleryDetail';




interface IAppProps {
}

interface IAppState {
    showLandingPage?: boolean;
}

export default class App extends React.Component<IAppProps, IAppState> {
    static displayName = App.name;

    constructor(props: IAppProps) {
        super(props);
        this.state = {
            showLandingPage: false
        }
    }

    render() {
        return (
            <AuthProvider>

                <Routes>
                    {["/home", "/"].map((path) => <Route key="appHome" path={path} element={this.state.showLandingPage ? <LandingPage /> : <AdminApp />} />)}

                    <Route key="publicAuth" path="/TUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FDeC9PVnN5cUE5SlJNQptdzRQSmVE96QWxzWkc1Z3VJMFNmWmYwZlRybllVzJQZmNXCjdsd0RubS8xdE1ZVBheTZpdmVTYlBkVlhDQ00eUtaUnNEYlY4NlREZjZ1YzE1TDAKYVV5TVdUMWVVUG5XajA1Q3" element={<PublicLogin returnUrl='/p' ></PublicLogin>} />
                    <Route key="publicGalleryAuth" path="/RQSmVE96QWxzWkc1Z3VJMFNmWmYwZlRybllVzJQZmNXCjdsd0RubS8xdE1ZVBheTZpdmVTYlBkVlhDQ00eUtaUnNEYlY4NlREZjZ1YzE1TDAKY" element={<PublicLogin returnUrl='/g/gallery' ></PublicLogin>} />
                    <Route element={<PublicAuth />}>
                        <Route key="publicHome" path="/p" element={<PublicHome />} />
                        <Route key="publicWizard" path="/p/wizard" element={<PublicWizard />}>
                            <Route path="list" element={<PublicWizardNewGuestData />}></Route>
                        </Route>
                        <Route key="publicThankYou" path="/p/thankyou" element={<PublicWizardThankYou />}></Route>
                        <Route key="publicGallery" path="/g/gallery" element={<PublicGallery />}></Route>
                    </Route>                    
                </Routes>
                <Routes>
                    <Route key="unauthorized" path="/Unauthorized" element={<Unauthorized />} />
                    <Route key="logout" path="/logout" element={<Logout />} />
                </Routes>
                <Routes>
                    <Route key="adminHome" path="/admin" element={<AdminApp />} >
                        <Route key="adminDashboard" path="dashboard" element={<AdminDashboard />}></Route>
                        <Route key="adminGuestList" path="guestlist" element={<AdminGuestList />}></Route>
                        <Route key="adminGuesData" path="guests/:userId" element={<AdminGuestData/>}></Route>
                        <Route key="adminSettings" path="settings" element={<AdminSettings/>}></Route>
                        <Route key="adminDatabase" path="database" element={<AdminDatabaseManage/>}></Route>
                        <Route key="adminTableauManage" path="tableau-manage" element={<AdminTableauManage/>}></Route>
                        <Route key="adminTableauSearch" path="tableau-search" element={<AdminTableauList/>}></Route>
                        <Route key="adminGalleryList" path="gallery" element={<AdminGalleryList/>}></Route>
                        <Route key="adminGalleryList" path="gallery/:galleryId" element={<AdminGalleryDetail/>}></Route>
                        
                    </Route>
                </Routes>
                <Routes>
                    <Route key="oauthCallback" path="/oauth/callback" element={ <OauthCallback/> }></Route>
                </Routes>

            </AuthProvider>
        );
    }
}
