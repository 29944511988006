import { Col, Row, Form, FloatingLabel, Button } from "react-bootstrap"
import ExportIcon from '@rsuite/icons/Export';
import AddOutlineIcon from '@rsuite/icons/AddOutline';

export enum DataGridFiltersFieldType {
    TEXT,
    CHOICE
}

export type DataGridFiltersField = {
    name: string,
    type: DataGridFiltersFieldType,
    fieldContext: [any, (value: any) => void],
    choiceOptions?: DataGridFiltersChoiceOption[]
}
export type DataGridFiltersChoiceOption = {
    label: string,
    value: any
}
export type DataGridFiltersProps = {
    fields: DataGridFiltersField[],
    onSearch: () => void,
    onClear: () => void,
    onNew: () => void,
    onExport: () => void,
    hideNew?: boolean | true,
    hideExport?: boolean | true
}

function DataGridFilters(options: DataGridFiltersProps) {
    return (
        <div className="dg-f-container">

            <Row>
                <Col className="dg-f-title">
                    RICERCA
                </Col>
                <Col className="dg-f-buttons">
                    {(() => {
                        if (!options.hideExport) {
                            return <Button className="dg-f-button t-alternate-button"
                                onClick={() => {
                                    options.onExport();
                                }}
                            >
                                <ExportIcon></ExportIcon>&nbsp;Esporta
                            </Button>
                        }
                    })() }
                    {(() => {
                        if (!options.hideNew) {
                            return <Button className="dg-f-button t-primary-button"
                                onClick={() => {
                                    options.onNew();
                                }}
                            >
                                <AddOutlineIcon></AddOutlineIcon>&nbsp;Aggiungi
                            </Button>
                        }
                    })()}
                </Col>
            </Row>
            <Row>
                {(() => {
                    const columns = [];
                    for (let field of options.fields) {

                        columns.push(
                            field.type === DataGridFiltersFieldType.TEXT ?
                                <>
                                    <Form.Group as={Col} xs="6" md="6" lg="4" controlId={`filter-${field.name}`}>
                                        <FloatingLabel controlId={`floatingName${field.name}`} label={field.name}>
                                            <Form.Control type="text" placeholder={field.name} onChange={(event: any) => { field.fieldContext[1](event.target.value); }} value={field.fieldContext[0]} />
                                        </FloatingLabel>
                                    </Form.Group>
                                </>
                                :
                                <>
                                    <Form.Group as={Col} xs="6" md="6" lg="4" controlId={`filter-${field.name}`}>
                                        <FloatingLabel controlId="floatingSelect" label={field.name}>
                                            <Form.Select aria-label="" onChange={(event: any) => { field.fieldContext[1](event.target.value); }} value={field.fieldContext[0]}  >
                                                {(() => {

                                                    const choices = [];
                                                    if (field.choiceOptions !== undefined) {
                                                        for (let choice of field.choiceOptions) {
                                                            choices.push(<option value={choice.value}>{choice.label}</option>)
                                                        }
                                                    }
                                                    return choices;
                                                })()}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Form.Group>
                                </>
                        );
                    }
                    return columns;
                })()}
            </Row >
            <Row className="dg-f-button-row">
                <Col className="dg-f-button-col">
                    <Button className="dg-f-button t-alternate-button" onClick={() => {
                        options.onClear();
                        let selects = document.querySelectorAll('.dg-f-container select');
                        [].forEach.call(selects, function (s) {
                            (s as HTMLSelectElement).value = "";
                        });
                    }}>PULISCI</Button>
                    <Button className="dg-f-button t-primary-button" onClick={options.onSearch}>CERCA</Button>
                </Col>
            </Row>
        </div>
    );
}

export default DataGridFilters;