import { forwardRef, useImperativeHandle, useState } from "react";
import { Alert } from "react-bootstrap";
import { JsxElement } from "typescript";

export interface IAlertRef {
    show(variant: string, heading: string, text: JSX.Element): any,
    hide(): any
}

const AlertComponent = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({ variant: "", heading: "", text: <></> });
    useImperativeHandle(ref, () => ({
        show(variant: string, heading: string, text: JSX.Element) {            
            setContent({ variant: variant, heading:heading, text :text});
            setShow(true);
        },
        hide() {
            setShow(false);
        }
    })
    );


    if (show) {

        return (
            <Alert variant={ content.variant} onClose={() => setShow(false)} dismissible>
                <Alert.Heading>{content.heading}</Alert.Heading>
                {content.text}                
            </Alert>
        );
    }
    else {
        return(<></>)
    }
})

export default AlertComponent;