import { Button, Container, Row, Col, Nav } from 'react-bootstrap';
import PublicWizardNumberParticipants from "./publicWizard_numberParticipants";
import CountdownTimer from "./Countdown"
import '../css/public-theme.scss'
import firstBackImage from '../../content/images/header-image.png'
import AgendaSlider from "./publicHomeHorizontalAgenda";
import { useRef } from "react";
import PublicLoader, { ILoaderRef } from "./publicLoaderComponent";

const PublicHome = () => {
    /*let navigate = useNavigate();*/
    const loaderRef = useRef<ILoaderRef>();
    const disableScrollRef = useRef(false);
    const TARGET_DATE_MS = new Date(2024, 8, 27, 10, 29, 59);    
    return (

        <div className="wrapper">
            <PublicLoader ref={loaderRef} props={{ noAutoHide: true, backgroundColor: "rgba(255,255,255,0.95)" }}></PublicLoader>           
            <Container fluid className="t-bg-first ">
                <Container>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <div className="t-logo">
                                <div>{process.env.REACT_APP_HIM_NAME}</div>
                                <div>&</div>
                                <div>{process.env.REACT_APP_HER_NAME}</div>
                            </div>
                        </Col>
                        <Col sm={8} md={8} lg={8} className="t-home-nav">
                            <Nav activeKey="">
                                <Nav.Item>
                                    <Nav.Link onClick={() => {
                                        window.scrollTo({ top: (document?.querySelector('.t-bg-second') as HTMLElement)?.offsetTop, behavior: 'auto' });
                                    }}>AGENDA</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Button onClick={() => {
                                        disableScrollRef.current = true;
                                        window.scrollTo({ top: (document?.querySelector('.t-bg-third') as HTMLElement)?.offsetTop, behavior: 'auto' });
                                        setTimeout(() => {
                                            disableScrollRef.current = false;
                                        }, 200);
                                    }}>CONFERMA LA TUA PRESENZA</Button>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="t-first-img-container">
                    <Row>
                        <Col sm={12} md={8} lg={8}>
                            <div className="tfi-text">
                                <div>FRANCESCO & MARICA</div>
                                <div>IL NOSTRO GIORNO SPECIALE!</div>
                                <div>In questo sito troverai informazioni utili pratiche sull'agenda del matrimonio e sulla conferma della partecipazione. Segnalaci eventuali intolleranze ed allergie.</div>
                                <div><Button onClick={() => {
                                    window.scrollTo({ top: (document?.querySelector('.t-bg-second') as HTMLElement)?.offsetTop, behavior: 'auto' });
                                }} className="t-alternate-button"><span>SCOPRI DI PI&ugrave;</span></Button></div>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <div className="tfi-image"><img src={firstBackImage} onLoad={() => { loaderRef?.current?.hide(); }} alt="first-back" /></div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <AgendaSlider disableScroll={disableScrollRef} showLoader={() => { loaderRef?.current?.show(); }} hideLoader={() => { loaderRef?.current?.hide(); }}></AgendaSlider>
            <Container fluid className="t-bg-third">
                <Container>
                    <Row>
                        <PublicWizardNumberParticipants showLoader={() => { loaderRef?.current?.show(); }}></PublicWizardNumberParticipants>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="t-bg-fourth">
                <CountdownTimer targetDate={TARGET_DATE_MS}></CountdownTimer>
            </Container>
        </div >
    );
}

export default PublicHome;