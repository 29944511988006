import { Loader } from "rsuite";
import { forwardRef, useImperativeHandle, useEffect, } from "react";
export interface IAdminLoaderRef {
    show(): any,
    hide(): any
}

const AdminLoader = forwardRef((props: any, ref) => {

    useImperativeHandle(ref, () => ({
        show() {
            document.querySelector(".admin-loader")?.classList.add("shown");
            window.scrollTo({ top: 0, behavior: 'auto' });
        },
        hide() {
            document.querySelector(".admin-loader")?.classList.remove("shown");
        }
    })
    );

    useEffect(() => {
        if (props?.props?.noAutoHide === undefined || !props.props.noAutoHide) {
            setTimeout(() => {
                //window.scrollTo({ top: 0, behavior: 'auto' });
                document.querySelector(".admin-loader")?.classList.remove("shown");
            }, 100);
        }

    }, [])

    return (
        <div className="admin-loader" style={{ "backgroundColor": props?.props?.backgroundColor || "rgba(255,255,255,0.5)" }}>
            <Loader center size="lg" backdrop />
        </div>
    );
})

export default AdminLoader;