import PublicLoader, { ILoaderRef } from './publicLoaderComponent';
import AlertComponent, { IAlertRef } from '../../../common/components/ts/Alert';
import { Button, Container, Row, Col, Nav, Form, FloatingLabel, Modal } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react';
import { Divider, Uploader } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';
import { useAuth } from '../../../common/components/ts/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

function PublicGallery() {
    const loaderRef = useRef<ILoaderRef>();
    const alertRef = useRef<IAlertRef>();
    const [fileList, setFileList] = React.useState<FileType[]>();
    const [showUploadDone, setShowUploadDone] = React.useState<boolean>(false);
    const [showComingSoon, setShowComingSoon] = React.useState<boolean>(false);
    const uploader = React.useRef();
    const authContext = useAuth();
    let uploadedCount = 0;
    let errorCount = 0;
    if (window.sessionStorage.getItem("gallery_upload_identifier") === null) {
        window.sessionStorage.setItem("gallery_upload_identifier", uuidv4());
    }
    const identifier = window.sessionStorage.getItem("gallery_upload_identifier");
    const [enableGallery, setEnableGallery] = useState<number>(0);
    const enableGallerySettingName = "enableGalleryBrowse";

    const fetchSetting = (name: string) => {
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/settings/${name}`, config)
            .then(response => {

                setEnableGallery(response.data);

                loaderRef?.current?.hide();
                return;
            })
            .catch(error => {
                loaderRef?.current?.hide();
                alertRef?.current?.show("danger", "Oh no!", <><div>Si &egrave; verificato un problema.</div></>);
                return;
            });
    }

    useEffect(() => {
        fetchSetting(enableGallerySettingName);
    }, [])
    return (
        <div className="wrapper t-gallery" style={{ height: "100%" , minHeight: "1000px" }}>
            <PublicLoader ref={loaderRef}></PublicLoader>
            <AlertComponent ref={alertRef}></AlertComponent>
            <Modal show={showUploadDone} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Completato</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Grazie per aver condiviso con noi le tue foto! Puoi caricarne altre oppure chiudere semplicemente la pagina</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="t-primary-button" onClick={() => { setShowUploadDone(false); }} >Ok</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showComingSoon} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>OPS..</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Questa sezione &egrave; ancora in fase di allestimento.. <br/> Aiutaci a realizzarla caricando le tue foto!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="t-primary-button" onClick={() => { setShowComingSoon(false); }} >Ok</Button>
                </Modal.Footer>
            </Modal>
            <Container fluid className="t-bg-first" style={{ height: "100%" }}>
                <Container>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <div className="t-logo">
                                <div>Francesco</div>
                                <div>&</div>
                                <div>Marica</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={12} className="title">
                            <div className="">
                                <div>BENTORNATO!</div>
                                <div>In questa sezione potrai caricare le foto e i video che hai scattato oppure visualizzare la Gallery del matrimonio. <br />
                                    Conserve il QR Code cos&igrave; da poter sempre tornare su quesa pagina e visualizzare gli aggiornamenti!</div>                                
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row >
                    {/*<Row style={{ width: "100%" }}>*/}
                        <Uploader
                            listType="picture"
                            fileList={fileList}
                            autoUpload={false}
                            headers={{ Authorization: `Bearer ${authContext.token}` }}
                            action={`${process.env.REACT_APP_API_BASE_URL}/api/gallery/upload?identifier=${identifier}`}
                            onChange={setFileList}
                            ref={uploader}
                            draggable
                            multiple
                            //onProgress={(percent: number, file: FileType) => { console.log(percent); }}
                            onReupload={(file: FileType) => {
                                if (errorCount > 0) { errorCount--; }
                                loaderRef?.current?.show();
                                (uploader?.current as any).start(file);
                            }}
                            onSuccess={(response: object, file: FileType) => {
                                uploadedCount++;
                                if (fileList?.length && (errorCount + uploadedCount) >= fileList?.length) {
                                    loaderRef?.current?.hide();
                                    if (uploadedCount >= fileList?.length) {
                                        setFileList([]);
                                        setShowUploadDone(true);
                                    }
                                }
                            }}
                            onError={(reason: object, file: FileType) => {
                                errorCount++;
                                loaderRef?.current?.hide();
                                alertRef?.current?.show("danger", "Oh no!", <><div>Si &egrave; verificato un problema durante il caricamento! Prova a ricaricare le immagini con la scritta Error cliccando sull'immagine stessa</div></>);
                            }}
                        >

                            <div style={{ flexDirection: "column", height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', whiteSpace: "normal" }}>
                                <div>Clicca o trascina i file da caricare e poi clicca sul pulsante</div>
                                <div>"AVVIA CARICAMENTO"</div>
                            </div>


                        </Uploader>
                    </Row>
                    <Divider></Divider>
                    <Row style={{ justifyContent: "center", textAlign: "center" }}>
                        <Col>
                            <Button className="t-primary-button"
                                disabled={!fileList || !fileList.length}
                                onClick={() => {
                                    uploadedCount = 0;
                                    errorCount = 0;
                                    loaderRef?.current?.show();
                                    (uploader?.current as any).start();
                                }}
                            >
                                AVVIA CARICAMENTO
                            </Button>
                        </Col></Row>


                </Container>
                <Container>
                    <Row style={{ marginTop: "24px" }}>
                        <Col sm={12} md={12} lg={12} className="title">
                            <div className="">
                                <div>OPPURE</div>
                                <div>Clicca sul pulsante in basso per andare alla Gallery</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row style={{ marginTop: "24px", marginBottom: "24px" }}>
                        <Col sm={12} md={12} lg={12} className="title">
                            <Button className="upload-button"
                                onClick={() => {
                                    if (enableGallery===0) {
                                        setShowComingSoon(true);
                                    }
                                }}
                            >
                                VAI ALLA GALLERY
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div >
    );
}

export default PublicGallery;