import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';

export interface ICountdownData {
    expired: boolean | null;
    days: number | null;
    hours: number | null;
    minutes: number | null;
    seconds: number | null;
}

const useCountdown = (targetDate: Date) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {            
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown: any) => {
    // calculate time left    
    if (countDown < 1000) {
        return [0, 0, 0, 0];
    }
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

const ShowCountdown = ({ days, hours, minutes, seconds } : any) => {
    return (
        <Container>
            <Row>
                <Col><p>COUNTDOWN</p></Col>
            </Row>
            <Row>
                <Col className="c-container">
                    <Row className="time">{days}</Row>
                    <Row className="label">GIORNI</Row>
                </Col>
                <Col className="c-container">
                    <Row className="time">{hours}</Row>
                    <Row className="label">ORE</Row>
                </Col>
                <Col className="c-container">
                    <Row className="time">{minutes}</Row>
                    <Row className="label">MINUTI</Row>
                </Col>
                <Col className="c-container">
                    <Row className="time">{seconds}</Row>
                    <Row className="label">SECONDI</Row>
                </Col>
            </Row>
        </Container>
    );
}

const CountdownTimer = ({ targetDate }: any) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    //const result = {} as ICountdownData;
    //if (days + hours + minutes + seconds <= 0) {
    //    result.expired = true;
    //} else {
    //    result.expired = false;
    //    result.days = days;
    //    result.hours = hours;
    //    result.minutes = minutes;
    //    result.seconds = seconds;
    //}
    return ShowCountdown({ days, hours, minutes, seconds });

};

export { useCountdown };
export default CountdownTimer;