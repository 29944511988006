import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Divider } from "rsuite";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";
import DataGridDisplay, { CellType, TableConfiguration } from "./dataGridDisplay";
import DataGridFilters, { DataGridFiltersFieldType, DataGridFiltersProps } from "./dataGridFilters";
import { Table } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import { useNavigate } from "react-router-dom";
import { renderToString } from 'react-dom/server';
import { ParticipantAge, ParticipantMenuChoice } from "../../common/types/Participant";
const { Cell } = Table;
function AdminGuestList() {
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const [apiData, setApiData] = useState<any>();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(parseInt(window.sessionStorage.getItem("grid_guests_page") || "1"));
    const [limit, setLimit] = useState(parseInt(window.sessionStorage.getItem("grid_guests_limit") || "10"));
    const [filterName, setFilterName] = useState(window.sessionStorage.getItem("grid_guests_filterName") || "");
    const [filterSurname, setFilterSurname] = useState(window.sessionStorage.getItem("grid_guests_filterSurname") || "");
    const [filterAge, setFilterAge] = useState(window.sessionStorage.getItem("grid_guests_filterAge") || null);
    const [filterAllergy, setFilterAllergy] = useState(window.sessionStorage.getItem("grid_guests_filterAllergy") || null);
    const [filterPreferences, setFilterPreferences] = useState(window.sessionStorage.getItem("grid_guests_filterPreference") || null);
    const authContext = useAuth();
    const navigate = useNavigate();

    const setPageSession = (page: number) => {
        window.sessionStorage.setItem("grid_guests_page", page.toString());
        setPage(page);
    }
    const setLimitSession = (limit: number) => {
        window.sessionStorage.setItem("grid_guests_limit", limit.toString());
        setLimit(limit);
    }
    const setFilterNameSession = (name: string) => {
        setFilterName(name);
        window.sessionStorage.setItem("grid_guests_filterName", name);
    }
    const setFilterAgeSession = (value: number | null) => {
        setFilterAge(value?.toString() || null);
        window.sessionStorage.setItem("grid_guests_filterAge", value?.toString() || "");
    }
    const setFilterSurnameSession = (value: string) => {
        setFilterSurname(value);
        window.sessionStorage.setItem("grid_guests_filterSurname", value);
    }
    const setFilterAllergySession = (value: boolean | null) => {
        setFilterAllergy(value?.toString() || null);
        window.sessionStorage.setItem("grid_guests_filterAllergy", value?.toString() || "");
    }
    const setFilterPreferencesSession = (value: boolean | null) => {
        setFilterPreferences(value?.toString() || null);
        window.sessionStorage.setItem("grid_guests_filterPreference", value?.toString() || "");
    }

    useEffect(() => {
        setSectionName("Lista Partecipanti");
    }, [])

    const renderConfig: Array<TableConfiguration> = [
        {
            header: "",
            dataKey: "id",
            grow: 0,
            sortable: false,
            type: CellType.CUSTOM,
            fixed: true,
            width: 50,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        <Button variant="link"
                            onClick={() => {
                                navigate(`/admin/guests/${rowData.id}`);
                            }}
                        >      <EditIcon></EditIcon>
                        </Button>

                    </Cell>
                );
            }
        },
        { header: "Nome", dataKey: "name", grow: 1, sortable: true, type: CellType.BUILTIN, fixed: false },
        { header: "Cognome", dataKey: "surname", grow: 1, sortable: true, type: CellType.BUILTIN, fixed: false },
        {
            header: renderToString(<>Et&agrave;</>), dataKey: "age", grow: 1, sortable: true, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {rowData.age === ParticipantAge.Adulto ? 'ADULTO' : 'BAMBINO'}
                    </Cell>
                );
            }
        },
        {
            header: renderToString(<>Scelta Men&ugrave;</>), dataKey: "menuChoice", grow: 1, sortable: true, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {rowData.menuChoice === null ? "-" : ParticipantMenuChoice[rowData.menuChoice]}
                    </Cell>
                );
            }
        },
        {
            header: "Allergie", dataKey: "hasAllergies", grow: 1, sortable: false, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {rowData.hasAllergies ? 'SI' : 'NO'}
                    </Cell>
                );
            }
        },
        {
            header: "Preferenze", dataKey: "hasPreferences", grow: 1, sortable: false, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {rowData.hasPreferences ? 'SI' : 'NO'}
                    </Cell>
                );
            }
        },
        {
            header: "Data Registrazione", dataKey: "creationDate", grow: 1, sortable: true, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {new Date(rowData.creationDate).toLocaleString()}
                    </Cell>
                );
            }
        },

    ]

    const fetchData = (page: number, limit: number, sortCol?: string, sortType?: any) => {
        showLoader();
        let config = {
            params: {
                name: filterName,
                surname: filterSurname,
                age: filterAge,
                hasAllergies: filterAllergy,
                hasPreferences: filterPreferences,
                page: page,
                pageSize: limit,
                sortColumn: sortCol,
                sortDirection: sortType
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/AdminGuestList", config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    setApiData(response.data.items);
                    setPage(response.data.page);
                    setTotal(response.data.totalCount);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const filterConfig: DataGridFiltersProps = {
        fields: [{
            name: "Nome",
            type: DataGridFiltersFieldType.TEXT,
            fieldContext: [filterName, setFilterNameSession]
        },
        {
            name: "Cognome",
            type: DataGridFiltersFieldType.TEXT,
            fieldContext: [filterSurname, setFilterSurnameSession]
        },
        {
            name: renderToString(<>Et&agrave;</>),
            type: DataGridFiltersFieldType.CHOICE,
            fieldContext: [filterAge, setFilterAgeSession],
            choiceOptions:
                [
                    { label: "Tutto", value: "" },
                    { label: ParticipantAge[ParticipantAge.Adulto], value: ParticipantAge.Adulto },
                    { label: ParticipantAge[ParticipantAge.Bambino], value: ParticipantAge.Bambino }
                ]
        },
        {
            name: "Allergie",
            type: DataGridFiltersFieldType.CHOICE,
            fieldContext: [filterAllergy, setFilterAllergySession],
            choiceOptions:
                [
                    { label: "Tutto", value: "" },
                    { label: "SI", value: true },
                    { label: "NO", value: false }
                ]
        },
        {
            name: "Preferenze",
            type: DataGridFiltersFieldType.CHOICE,
            fieldContext: [filterPreferences, setFilterPreferencesSession],
            choiceOptions:
                [
                    { label: "Tutto", value: "" },
                    { label: "SI", value: true },
                    { label: "NO", value: false }
                ]
        }
        ],
        onSearch: () => fetchData(1, limit),
        onClear: () => {
            setFilterNameSession("");
            setFilterSurnameSession("");
            setFilterAgeSession(null)
            setFilterAllergySession(null)
            setFilterPreferencesSession(null)
        },
        onNew: () => { navigate("/admin/guests/0") },
        onExport: () => {
            showLoader();
            let date = new Date();
            let config = {
                params: {
                },
                headers: { Authorization: `Bearer ${authContext.token}` }
            }
            const client = axios.create({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                responseType: 'blob'
            });
            client.get(`/api/AdminGuestList/export`, config)
                .then(response => {                    
                    const href = URL.createObjectURL(response.data);

                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `Export_Partecipanti_${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.xlsx`); 
                    document.body.appendChild(link);
                    link.click();
                    
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    hideLoader();
                    return;
                })
                .catch(error => {
                    hideLoader();
                    showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                    return;
                });
        },
        hideExport: false
    }
    return (
        <Container fluid className="admin-main-content">
            <DataGridFilters {...filterConfig}></DataGridFilters>
            <Divider></Divider>
            <DataGridDisplay
                showLoader={showLoader}
                hideLoader={hideLoader}
                fetchData={fetchData}
                useDataContext={() => [apiData, setApiData]}
                usePageContext={() => [page, setPageSession]}
                useTotalContext={() => [total, setTotal]}
                useLimitContext={() => [limit, setLimitSession]}
                renderConfiguration={renderConfig}
            ></DataGridDisplay>
        </Container>
    );
}

export default AdminGuestList;