import * as React from 'react';
import '../css/landingPage.css';

export default class LandingPage extends React.Component {
    static displayName = LandingPage.name;

    componentDidMount() {
        setTimeout(() => {
            var w = window.innerWidth;
            if ((w - 50) < 400) {
                var iw = w - 50;
                var stdEl = document.getElementById("std-image") as HTMLElement;
                stdEl.style.width = iw + 'px';
            }

            var h = window.innerHeight;
            var ch = document.getElementsByClassName("content")[0].clientHeight;
            console.log(ch);
            if (h > ch) {
                var m = (h - ch) / 2;
                (document.getElementsByClassName("content")[0] as HTMLElement).style.marginTop = m + 'px';
            }


            (document.getElementsByClassName("bg-overlay")[0] as HTMLElement).classList.add("bg-overlay-unlocked");
        }, 500);
    }

    render() {
        return (
            <div className="lp-container">
                <div className="bg-image"> </div>
                <div className="bg-overlay" > </div>
                <div className="content">
                    <img id="std-image" alt="Save the Date" src={process.env.PUBLIC_URL + '/savethedate.png'} />
                    <hr />
                    <div> 27. 09. 2024</div>
                </div>
            </div>
        );
    }


}
