import { useSectionNameContext } from "./adminApp";
import { Button, Container, Row, Col, Form, FloatingLabel, Anchor, Modal } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { ParticipantAge, ParticipantAllergy, ParticipantListItem, ParticipantMenuChoice, ParticipantUnfavorability } from "../../common/types/Participant";
import { useAuth } from "../../common/components/ts/AuthProvider";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
function AdminGuestData() {
    const allergies = ["Glutine", "Crostacei", "Molluschi", "Frutta secca", "Soia e derivati", "Uova", "Lattosio", "Senape", "Sesamo", "Sedano", "Lupini"];
    const unfavored = ["Pesce Cotto", "Pesce Crudo", "Carne", "Latticini e Formaggi", "Ogni derivato animale"];
    const { userId } = useParams();
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const [showDelete, setShowDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [participant, setParticipant] = useState<ParticipantListItem>({} as ParticipantListItem);
    const authContext = useAuth();
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        showLoader();
        setSectionName("Dettaglio Partecipante");
        fetchUserById();
    }, [])

    const handleDeleteHide = ()=> {
        setShowDelete(false);
    }

    const handleDeleteConfirmHide = () => {
        setShowDeleteConfirmation(false);
    }

    const handleDeleteConfirm = () => {
        showLoader();
        deleteUserById();
    }
    const deleteUserById = () => {        
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.delete(`/api/AdminGuestList/${userId}`, config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    setShowDelete(false);
                    setShowDeleteConfirmation(true);
                }
                else {
                    hideLoader();
                    setShowDelete(false);
                    showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div></>);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                setShowDelete(false);
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }
    const fetchUserById = (newUserId?: string) => {
        let localUserId = userId;
        if (newUserId !== undefined) {
            localUserId = newUserId;
        }
        if (localUserId === undefined || localUserId === "0") {
            setParticipant({ ...participant, allergies: getInitialAllergies(), unfavorability: getInitialUnfavor() })
            hideLoader();
            return;
        }
        
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/AdminGuestList/${localUserId}`, config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    console.log(response);
                    setParticipant(response.data);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const participantExists = (participant: ParticipantListItem, callback: any) => {
        let config = {
            params: {
                name: participant.name,
                surname: participant.surname,
                age: participant.age
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/AdminGuestList/exists/${userId}`, config)
            .then(response => {                
                if (response.data) {
                    hideLoader();
                    showAlert("Oh no!", <><div>{participant.name} {participant.surname} &egrave; gi&agrave; presente nell'elenco dei partecipanti!</div></>);
                }
                callback(response.data);
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                callback(true);
                return;
            });
    }
    const upsertParticipant = () => {
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        if (userId === undefined || userId === "0") {
            client.post("/api/AdminGuestList", participant, config)
                .then(response => {                    
                    hideLoader();                    
                    showAlert("", <>Operazione eseguita correttamente</>, "success");
                    navigate(`/admin/guests/${response.data}`);
                    fetchUserById(response.data);               
                    return;
                })
                .catch(error => {
                    hideLoader();
                    showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                    return;
                });
        } else {
            client.put(`/api/AdminGuestList/${userId}`, participant, config)
                .then(response => {
                    hideLoader();                    
                    showAlert("", <>Operazione eseguita correttamente</>, "success");
                    fetchUserById();
                    return;
                })
                .catch(error => {
                    hideLoader();
                    showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                    return;
                });
        }
    }
    const handleSubmit = (event: any) => {
        showLoader();
        const form = event.currentTarget;
        let formValid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
        if (formValid) {
            participantExists(participant, (result: boolean) => {
                if (!result) {
                    upsertParticipant();
                }
            })
        }
        else {
            hideLoader();
        }
    };

    const handleInputNameChange = (event: any) => {
        if (participant !== undefined) {
            setParticipant({ ...participant, name: event.target.value });
        }
    };
    const handleInputSurnameChange = (event: any) => {
        if (participant !== undefined) {
            setParticipant({ ...participant, surname: event.target.value });
        }
    };

    const handleInputAgeChange = (event: any) => {
        if (participant !== undefined) {
            if (event.target.getAttribute("data-age") === "adulto") {
                setParticipant({ ...participant, age: ParticipantAge.Adulto, menuChoice: null });
            }
            if (event.target.getAttribute("data-age") === "bambino") {
                setParticipant({ ...participant, age: ParticipantAge.Bambino, menuChoice: null });
            }
        }        
    };

    const handleAllergySwitchChange = (event: any) => {
        let itemName = event.target.getAttribute("data-item");
        let propIndex = participant.allergies.findIndex(x => x.allergy === itemName);
        let prevParticipantAllergies = [...participant.allergies];
        prevParticipantAllergies[propIndex].hasAllergy = event.target.checked;
        setParticipant({ ...participant, allergies: prevParticipantAllergies });
    }

    const handleUnfavorSwitchChange = (event: any) => {
        let itemName = event.target.getAttribute("data-item");
        let propIndex = participant.unfavorability.findIndex(x => x.unfavorability === itemName);
        let prevParticipantUnfavor = [...participant.unfavorability];
        prevParticipantUnfavor[propIndex].hasUnfavorability = event.target.checked;
        setParticipant({ ...participant, unfavorability: prevParticipantUnfavor })
    }

    const handleInputMenuChoiceChange = (event: any) => {       
        updateMenuChoice(Number.parseInt(event.target.getAttribute("data-choice")));

    };

    const updateOtherAllergy = (allergy: any) => {
        setParticipant({ ...participant, otherAllergy: allergy });
    }

    const updateMenuChoice = (choice: number) => {        
        setParticipant({ ...participant, menuChoice: choice, allergies: getInitialAllergies(), unfavorability: getInitialUnfavor(), otherAllergy: "" });
        console.log(participant);
    }

    const getInitialAllergies = () => {
        let initialAllergies: ParticipantAllergy[] = [];
        for (let all of allergies) {
            initialAllergies.push({ allergy: all, hasAllergy: false });
        }
        return initialAllergies; //setParticipant({...participant, allergies: initialAllergies });
    }

    const getInitialUnfavor = () => {
        let initialUnfavor: ParticipantUnfavorability[] = [];
        for (let unfavor of unfavored) {
            initialUnfavor.push({ unfavorability: unfavor, hasUnfavorability: false });
        }
        return initialUnfavor;//setParticipant({ ...participant, unfavorability: initialUnfavor});
    }
    return (
        <Container fluid className="admin-main-content t-guest-data">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div id={`details`} className="t-participant-data">
                    <>
                        <Modal show={showDelete} onHide={handleDeleteHide} backdrop="static"
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Attenzione</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Confermare l'eliminazione dell'invitato {participant!.name} {participant!.surname} ?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="t-alternate-button" onClick={handleDeleteHide}>
                                    Annulla
                                </Button>
                                <Button className="t-primary-button danger" onClick={handleDeleteConfirm} >Elimina</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showDeleteConfirmation} onHide={handleDeleteConfirmHide} backdrop="static"
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Info</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Operazione eseguita correttamente</p>
                            </Modal.Body>
                            <Modal.Footer>                                
                                <Button className="t-primary-button" onClick={() => { navigate("/admin/guestList"); }} >Ok</Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col>
                                <Button className="t-primary-button" style={{ "margin": "12px 12px" }} onClick={() => { navigate("/admin/guestlist") }}>Indietro</Button>
                                
                                <Button disabled={ userId === undefined || userId === "0"} className="t-primary-button danger" onClick={() => { setShowDelete(true); }}>Elimina</Button>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group as={Col} controlId={`validationName`}>
                                    <FloatingLabel controlId={`floatingName`} label="Nome">
                                        <Form.Control type="text" placeholder="Nome" required onChange={handleInputNameChange} value={participant.name!} />
                                        <Form.Control.Feedback type="invalid">
                                            Nome obbligatorio
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group as={Col} className="form-label-group" controlId={`validationSurname`}>
                                    <FloatingLabel controlId={`floatingSurname`} label="Cognome">
                                        <Form.Control type="text" placeholder="Cognome" required onChange={handleInputSurnameChange} value={participant.surname!} />
                                        <Form.Control.Feedback type="invalid">
                                            Cognome obbligatorio
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col className="t-radio-age">
                                <Form.Check type="radio" id="radio-adulto" >
                                    <Form.Check.Input type="radio" required name={`group`} onChange={handleInputAgeChange} data-age="adulto" checked={participant.age === ParticipantAge.Adulto} />
                                    <Form.Check.Label>Adulto</Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">
                                        Selezionare un valore
                                    </Form.Control.Feedback>
                                </Form.Check>
                                <Form.Check type="radio" id="radio-bambino" >
                                    <Form.Check.Input type="radio" required name={`group`} onChange={handleInputAgeChange} data-age="bambino" checked={participant.age === ParticipantAge.Bambino} />
                                    <Form.Check.Label>Bambino</Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">
                                        &nbsp;
                                    </Form.Control.Feedback>
                                </Form.Check>
                            </Col>
                        </Row>

                    </>
                    <div className="t-allergies-form">
                        {participant?.age === ParticipantAge.Adulto || (participant?.age === ParticipantAge.Bambino && participant?.menuChoice === ParticipantMenuChoice.ADULTO) ?
                            <>
                                {(() => {
                                    if (participant?.age === ParticipantAge.Bambino) {
                                        return <Anchor className="t-link" onClick={() => {
                                            updateMenuChoice(ParticipantMenuChoice.BAMBINO);
                                        }}>Torna al menu bimbi</Anchor>;
                                    }
                                })()}
                                <Row className="title">
                                    <Col>
                                        <div>Preferenze alimentari</div>
                                    </Col>
                                </Row>
                                <Row className="subtitle">
                                    <Col>
                                        <div>Seleziona eventuali allergie o intolleranze a qualcuno di questi alimenti</div>
                                    </Col>
                                </Row>
                                {(() => {
                                    const allergiesSwitch = [];
                                    for (let allergy of allergies) {
                                        allergiesSwitch.push(
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`allergy-switch${allergy.toString()}`}
                                                        key={`allergy-switch${allergy.toString()}`}
                                                        label={allergy}
                                                        data-item={allergy}
                                                        onChange={handleAllergySwitchChange}
                                                        checked={participant.allergies.findIndex(x => x.allergy === allergy) >= 0
                                                            && participant.allergies[participant.allergies.findIndex(x => x.allergy === allergy)].hasAllergy === true
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                    return allergiesSwitch;
                                })()}
                                <Row>
                                    <Col>
                                        <Form.Group key={`allergy-tarea`} className="mb-3" controlId={`allergy-tarea`}>
                                            <Form.Label>Altro / Specifiche</Form.Label>
                                            <Form.Control as="textarea" rows={3} onChange={(event: any) => { updateOtherAllergy(event.target.value); }} value={participant?.otherAllergy !== null ? participant.otherAllergy : ""} />
                                        </Form.Group>
                                    </Col></Row>
                                <Row className="subtitle">
                                    <Col>
                                        <div>Seleziona eventuali alimenti non graditi</div>
                                    </Col>
                                </Row>
                                {(() => {
                                    const allergiesSwitch = [];
                                    for (let unfavor of unfavored) {
                                        allergiesSwitch.push(
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`allergy-switch${unfavor.toString()}`}
                                                        key={`allergy-switch${unfavor.toString()}`}
                                                        label={unfavor}
                                                        data-item={unfavor}
                                                        onChange={handleUnfavorSwitchChange}
                                                        checked={participant.unfavorability.findIndex(x => x.unfavorability === unfavor) >= 0
                                                            && participant.unfavorability[participant.unfavorability.findIndex(x => x.unfavorability === unfavor)].hasUnfavorability === true
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                    return allergiesSwitch;
                                })()}
                            </>
                            :
                            <>
                                <Row className="title">
                                    <Col>
                                        <div>Preferenze alimentari</div>
                                    </Col>
                                </Row>
                                <Row className="subtitle">
                                    <Col>
                                        <div>Il men&ugrave; Bimbi &egrave; composto da</div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <ul>
                                            <li>Prosciutto Cotto/Crudo, Mozzarella</li>
                                            <li>Pasta al sugo (Pennette/Orecchiette)</li>
                                            <li>Carne di pollo (Birbe/Cotoletta/Fettine)</li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row className="subtitle">
                                    <Col>
                                        <div>Esprimi una preferenza</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="t-radio-choice">
                                        <Form.Check type="radio" id="radio-mc-bambino" >
                                            <Form.Check.Input type="radio" required name={`group-mc`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.BAMBINO} checked={participant?.menuChoice === ParticipantMenuChoice.BAMBINO} />
                                            <Form.Check.Label>Va bene il men&ugrave; bimbi</Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                Selezionare un valore
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                        <Form.Check type="radio" id="radio-mc-adulto" >
                                            <Form.Check.Input type="radio" required name={`group-mc`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.ADULTO} checked={participant?.menuChoice === ParticipantMenuChoice.ADULTO} />
                                            <Form.Check.Label>Preferisco il men&ugrave; adulti</Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                &nbsp;
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                        <Form.Check type="radio" id="radio-mc-bambino-mod" >
                                            <Form.Check.Input type="radio" required name={`group-mc`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.BAMBINO_MOD} checked={participant?.menuChoice === ParticipantMenuChoice.BAMBINO_MOD} />
                                            <Form.Check.Label>Va bene il men&ugrave; bimbi ma con queste modifiche</Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                                &nbsp;
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                    </Col>
                                </Row>
                                {(() => {
                                    if (participant?.menuChoice === ParticipantMenuChoice.BAMBINO_MOD) {
                                        return <Row>
                                            <Col>
                                                <Form.Group key={`allergy-tarea`} className="mb-3" controlId={`allergy-tarea`}>
                                                    <Form.Label>Altro / Specifiche</Form.Label>
                                                    <Form.Control as="textarea" rows={3} onChange={(event: any) => { updateOtherAllergy(event.target.value); }} value={participant?.otherAllergy !== null ? participant.otherAllergy : ""} />
                                                </Form.Group>
                                            </Col></Row>
                                            ;
                                    }
                                })()}
                            </>
                        }
                    </div>
                    <Row className="button">                        
                        <Col>
                            <Button type="submit" className="t-primary-button">Salva</Button>
                        </Col>
                    </Row >
                </div>
            </Form>
        </Container>
    );
}



//function ParticipantMenuData({ participantProp, updateAllergies, updateOtherAllergy, updateUnfavor,  updateMenuChoice }: any) {
//    const allergies = ["Glutine", "Crostacei", "Molluschi", "Frutta secca", "Soia e derivati", "Uova", "Lattosio", "Senape", "Sesamo", "Sedano", "Lupini"];
//    const unfavored = ["Pesce Cotto", "Pesce Crudo", "Carne", "Latticini e Formaggi", "Ogni derivato animale"];
//    const [participantAllergies, setParticipantAllergies] = useState<ParticipantAllergy[]>(participantProp === undefined || participantProp.allergies === undefined ? [] : [...participantProp.allergies]);
//    const [participantUnfavor, setParticipantUnfavor] = useState<ParticipantUnfavorability[]>(participantProp === undefined || participantProp.unfavorability === undefined ? [] : [...participantProp.unfavorability]);

//    const handleSubmit = (event: any) => {
//        showLoader();
//        const form = event.currentTarget;
//        let isValid = form.checkValidity();
//        event.preventDefault();
//        event.stopPropagation();
//        if (isValid) {
//            updateAllergies(participantAllergies, participantIndex);
//            updateUnfavor(participantUnfavor, participantIndex);
//            nextStep(3);
//        }
//        else {
//            hideLoader();
//        }
//    };

//    const handleAllergySwitchChange = (event: any) => {
//        let itemName = event.target.getAttribute("data-item");
//        if (participantAllergies === undefined) {
//            setParticipantAllergies([{ allergy: itemName, hasAllergy: event.target.checked }]);
//        }
//        else {
//            let propIndex = participantAllergies.findIndex(x => x.allergy === itemName);
//            if (propIndex < 0) {
//                setParticipantAllergies(participantAllergies => [...participantAllergies, { allergy: itemName, hasAllergy: event.target.checked }]);
//            }
//            else {
//                let prevParticipantAllergies = [...participantAllergies];
//                prevParticipantAllergies[propIndex].hasAllergy = event.target.checked;
//                setParticipantAllergies([...prevParticipantAllergies])
//            }
//        }
//        //updateAllergies(participantAllergies, participantIndex);
//    }

//    const handleUnfavorSwitchChange = (event: any) => {
//        let itemName = event.target.getAttribute("data-item");
//        if (participantUnfavor === undefined) {
//            setParticipantUnfavor([{ unfavorability: itemName, hasUnfavorability: event.target.checked }]);
//        }
//        else {
//            let propIndex = participantUnfavor.findIndex(x => x.unfavorability === itemName);
//            if (propIndex < 0) {
//                setParticipantUnfavor(participantUnfavor => [...participantUnfavor, { unfavorability: itemName, hasUnfavorability: event.target.checked }]);
//            }
//            else {
//                let prevParticipantUnfavor = [...participantUnfavor];
//                prevParticipantUnfavor[propIndex].hasUnfavorability = event.target.checked;
//                setParticipantUnfavor([...participantUnfavor])
//            }
//        }

//        //updateUnfavor(participantUnfavor, participantIndex);
//    }

//    const handleInputMenuChoiceChange = (event: any) => {
//        updateOtherAllergy("", participantIndex);
//        updateMenuChoice(Number.parseInt(event.target.getAttribute("data-choice")), participantIndex);
//    };

//    useEffect(() => {

//        for (let itemName of allergies) {
//            let propIndex = participantAllergies.findIndex(x => x.allergy === itemName);
//            if (propIndex < 0) {
//                setParticipantAllergies(participantAllergies => [...participantAllergies, { allergy: itemName, hasAllergy: false }]);
//            }
//        }

//        for (let itemName of unfavored) {
//            let propIndex = participantUnfavor.findIndex(x => x.unfavorability === itemName);
//            if (propIndex < 0) {
//                setParticipantUnfavor(participantUnfavor => [...participantUnfavor, { unfavorability: itemName, hasUnfavorability: false }]);
//            }
//        }
//    }, [])

//    return (
//        currentDataStep !== 2 ? <></> :
//            participantProp?.age === ParticipantAge.Adulto || (participantProp?.age === ParticipantAge.Bambino && participantProp?.menuChoice === ParticipantMenuChoice.ADULTO) ?
//                <>
//                        {(() => {
//                            if (participantProp?.age === ParticipantAge.Bambino) {
//                                return <Anchor className="t-link" onClick={() => {
//                                    updateMenuChoice(ParticipantMenuChoice.BAMBINO, participantIndex);
//                                    setParticipantAllergies([]);
//                                    setParticipantUnfavor([]);
//                                    updateAllergies([], participantIndex);
//                                    updateUnfavor([], participantIndex);
//                                    updateOtherAllergy("", participantIndex);
//                                }}>Torna al menu bimbi</Anchor>;
//                            }
//                        })()}
//                        <Row className="title">
//                            <Col>
//                                <div>Preferenze alimentari</div>
//                            </Col>
//                        </Row>
//                        <Row className="subtitle">
//                            <Col>
//                                <div>Seleziona eventuali allergie o intolleranze a qualcuno di questi alimenti</div>
//                            </Col>
//                        </Row>
//                        {(() => {
//                            const allergiesSwitch = [];
//                            for (let allergy of allergies) {
//                                allergiesSwitch.push(
//                                    <Row>
//                                        <Col>
//                                            <Form.Check
//                                                type="switch"
//                                                id={`allergy-switch${allergy.toString()}-${participantIndex.toString()}`}
//                                                key={`allergy-switch${allergy.toString()}-${participantIndex.toString()}`}
//                                                label={allergy}
//                                                data-item={allergy}
//                                                onChange={handleAllergySwitchChange}
//                                                checked={participantAllergies !== null && participantAllergies !== undefined
//                                                    && participantAllergies !== undefined
//                                                    && participantAllergies.findIndex(x => x.allergy === allergy) >= 0
//                                                    && participantAllergies[participantAllergies.findIndex(x => x.allergy === allergy)].hasAllergy === true
//                                                }
//                                            />
//                                        </Col>
//                                    </Row>
//                                );
//                            }
//                            return allergiesSwitch;
//                        })()}
//                        <Row>
//                            <Col>
//                                <Form.Group key={`allergy-tarea-${participantIndex.toString()}`} className="mb-3" controlId={`allergy-tarea-${participantIndex.toString()}`}>
//                                    <Form.Label>Altro / Specifiche</Form.Label>
//                                    <Form.Control as="textarea" rows={3} onChange={(event: any) => { updateOtherAllergy(event.target.value, participantIndex); }} value={participantProp !== undefined ? participantProp.otherAllergy : ""} />
//                                </Form.Group>
//                            </Col></Row>
//                        <Row className="subtitle">
//                            <Col>
//                                <div>Seleziona eventuali alimenti non graditi</div>
//                            </Col>
//                        </Row>
//                        {(() => {
//                            const allergiesSwitch = [];
//                            for (let unfavor of unfavored) {
//                                allergiesSwitch.push(
//                                    <Row>
//                                        <Col>
//                                            <Form.Check
//                                                type="switch"
//                                                id={`allergy-switch${unfavor.toString()}-${participantIndex.toString()}`}
//                                                key={`allergy-switch${unfavor.toString()}-${participantIndex.toString()}`}
//                                                label={unfavor}
//                                                data-item={unfavor}
//                                                onChange={handleUnfavorSwitchChange}
//                                                checked={participantUnfavor !== null && participantUnfavor !== undefined
//                                                    && participantUnfavor !== undefined
//                                                    && participantUnfavor.findIndex(x => x.unfavorability === unfavor) >= 0
//                                                    && participantUnfavor[participantUnfavor.findIndex(x => x.unfavorability === unfavor)].hasUnfavorability === true
//                                                }
//                                            />
//                                        </Col>
//                                    </Row>
//                                );
//                            }
//                            return allergiesSwitch;
//                        })()}
//                        <Row className="button">

//                            <Col>
//                                <Button className="t-alternate-button" onClickCapture={() => { nextStep(1); }}>Indietro</Button>
//                            </Col>


//                            <Col>
//                                <Button type="submit" className="t-primary-button">Avanti</Button>
//                            </Col>
//                        </Row>
//                </>
//                :
//                <>
//                        <Row className="title">
//                            <Col>
//                                <div>Preferenze alimentari</div>
//                            </Col>
//                        </Row>
//                        <Row className="subtitle">
//                            <Col>
//                                <div>Il men&ugrave; Bimbi &egrave; composto da</div>
//                            </Col>
//                        </Row>

//                        <Row>
//                            <Col>
//                                <ul>
//                                    <li>Prosciutto Cotto/Crudo, Mozzarella</li>
//                                    <li>Pasta al sugo (Pennette/Orecchiette)</li>
//                                    <li>Carne di pollo (Birbe/Cotoletta/Fettine)</li>
//                                </ul>
//                            </Col>
//                        </Row>
//                        <Row className="subtitle">
//                            <Col>
//                                <div>Esprimi una preferenza</div>
//                            </Col>
//                        </Row>
//                        <Row>
//                            <Col className="t-radio-choice">
//                                <Form.Check type="radio" id="radio-mc-bambino" data-index={participantIndex} >
//                                    <Form.Check.Input type="radio" required name={`group-mc-${participantIndex.toString()}`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.BAMBINO} checked={participantProp?.menuChoice === ParticipantMenuChoice.BAMBINO} />
//                                    <Form.Check.Label>Va bene il men&ugrave; bimbi</Form.Check.Label>
//                                    <Form.Control.Feedback type="invalid">
//                                        Selezionare un valore
//                                    </Form.Control.Feedback>
//                                </Form.Check>
//                                <Form.Check type="radio" id="radio-mc-adulto" data-index={participantIndex} >
//                                    <Form.Check.Input type="radio" required name={`group-mc-${participantIndex.toString()}`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.ADULTO} checked={participantProp?.menuChoice === ParticipantMenuChoice.ADULTO} />
//                                    <Form.Check.Label>Preferisco il men&ugrave; adulti</Form.Check.Label>
//                                    <Form.Control.Feedback type="invalid">
//                                        &nbsp;
//                                    </Form.Control.Feedback>
//                                </Form.Check>
//                                <Form.Check type="radio" id="radio-mc-bambino-mod" data-index={participantIndex} >
//                                    <Form.Check.Input type="radio" required name={`group-mc-${participantIndex.toString()}`} onChange={handleInputMenuChoiceChange} data-choice={ParticipantMenuChoice.BAMBINO_MOD} checked={participantProp?.menuChoice === ParticipantMenuChoice.BAMBINO_MOD} />
//                                    <Form.Check.Label>Va bene il men&ugrave; bimbi ma con queste modifiche</Form.Check.Label>
//                                    <Form.Control.Feedback type="invalid">
//                                        &nbsp;
//                                    </Form.Control.Feedback>
//                                </Form.Check>
//                            </Col>
//                        </Row>
//                        {(() => {
//                            if (participantProp?.menuChoice === ParticipantMenuChoice.BAMBINO_MOD) {
//                                return <Row>
//                                    <Col>
//                                        <Form.Group key={`allergy-tarea-${participantIndex.toString()}`} className="mb-3" controlId={`allergy-tarea-${participantIndex.toString()}`}>
//                                            <Form.Label>Altro / Specifiche</Form.Label>
//                                            <Form.Control as="textarea" rows={3} onChange={(event: any) => { updateOtherAllergy(event.target.value, participantIndex); }} value={participantProp !== undefined ? participantProp.otherAllergy : ""} />
//                                        </Form.Group>
//                                    </Col></Row>
//                                    ;
//                            }
//                        })()}
//                        <Row className="button">

//                            <Col>
//                                <Button className="t-alternate-button" onClickCapture={() => { nextStep(1); }}>Indietro</Button>
//                            </Col>


//                            <Col>
//                                <Button type="submit" className="t-primary-button">Avanti</Button>
//                            </Col>
//                        </Row>
//                </>
//    );
//}


export default AdminGuestData;