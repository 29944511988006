import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "./AuthProvider";

function OauthCallback() {
    const query = useLocation();
    const authProvider = useAuth();
    authProvider.manageCallback(new URLSearchParams(query.search).get("code"));
    return (<></>);
}

export default OauthCallback;