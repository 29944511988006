import { useRef, useEffect, useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { Col, Container, Row, Modal, NavLink } from "react-bootstrap"
export default function AgendaSlider({ disableScroll, showLoader, hideLoader }: any) {
    const scrollRef = useRef();
    const isAtStart = useRef(true);
    const isAtEnd = useRef(false);
    const isInView = useRef(false);
    let lastY: any;
    const [showMapCerimonia, setShowMapCerimonia] = useState(false);

    const handleCloseCerimonia = () => setShowMapCerimonia(false);
    const handleShowCerimonia = () => {
        showLoader(); setShowMapCerimonia(true);
    }

    const [showMapRicevimento, setShowMapRicevimento] = useState(false);

    const handleCloseRicevimento = () => setShowMapRicevimento(false);
    const handleShowRicevimento = () => {
        showLoader(); setShowMapRicevimento(true);
    }

    const manageScroll = (s: string) => {
        if (s === "up") {
            instaceRefAgenda?.current?.next();
            if (isAtEnd.current) {
                isInView.current = false;
                document.body.style.overflow = 'visible';
                document.documentElement.style.overflow = 'visible';
            }
        }
        if (s === "down") {
            instaceRefAgenda?.current?.prev();
            if (isAtStart.current) {
                isInView.current = false;
                document.body.style.overflow = 'visible';
                document.documentElement.style.overflow = 'visible';
            }
        }
    }

    const onScroll = (e: any) => {
        if (disableScroll.current) {
            return;
        }
        let elementTop = (document?.querySelector('#scrollElement') as HTMLElement)?.offsetTop;
        if ((isAtEnd.current && e.target.documentElement.scrollTop <= elementTop)
            || (isAtStart.current && e.target.documentElement.scrollTop >= elementTop)) {
            isInView.current = true;
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            window.scrollTo({ top: elementTop, behavior: 'auto' });
        }
    }

    const onWheel = (e: any) => {
        if (!isInView.current) return;
        e.deltaY > 0 ? manageScroll("up") : manageScroll("down");
    }

    const onTouch = (e: any) => {
        if (!isInView.current) return;
        e.preventDefault();
        e.stopPropagation();
        var currentY = e.touches[0].clientY;
        if (lastY === 0) {
            lastY = currentY;
            return;
        }

        if (currentY > lastY) {
            manageScroll("down");
        } else if (currentY < lastY) {
            manageScroll("up")
        }
        lastY = currentY;
    }

    const onTouchEnd = (e: any) => {
        lastY = 0;
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        window.addEventListener("wheel", onWheel);
        window.addEventListener("touchmove", onTouch);
        window.addEventListener("touchend", onTouchEnd);

        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("wheel", onWheel);
            window.removeEventListener("touchmove", onTouch);
            window.removeEventListener("touchend", onTouchEnd);

        };
    }, [])

    const [sliderRefAgenda, instaceRefAgenda] = useKeenSlider<HTMLDivElement>(
        {
            loop: false,
            rubberband: false,
            vertical: false,
            breakpoints: {
                "(max-width: 992.98px)": {
                    slides: {
                        perView: 1
                    }
                },
                "(min-width: 993px) and (max-width: 1399.98px)": {
                    slides: {
                        perView: 2
                    }
                }
            },
            slides: {
                perView: 3,
                /*origin: "center",*/
            },
            slideChanged(props) {
                if (props.track.details.abs >= props.track.details.maxIdx) {
                    isAtEnd.current = true;
                    isAtStart.current = false;
                }
                if (props.track.details.abs <= props.track.details.minIdx) {
                    isAtEnd.current = false;
                    isAtStart.current = true;
                }
            }
        }
    )

    return (
        <Container fluid className="t-bg-second">
            <Modal show={showMapCerimonia} onHide={handleCloseCerimonia} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Mappa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        onLoad={hideLoader}
                        title="cerimoniamap"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.9980974995733!2d14.448504795212102!3d40.915787597527306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133bae0dee7e7873%3A0x44f86482c95a9bf1!2sSantuario%20della%20Madonna%20della%20Speranza%2C%20Convento%20dei%20Frati%20Minori%20di%20San%20Vito!5e0!3m2!1sit!2sit!4v1717140840555!5m2!1sit!2sit"
                        width="100%" height="450" style={{ "border": 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Modal.Body>
            </Modal>
            <Modal show={showMapRicevimento} onHide={handleCloseRicevimento} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Mappa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        onLoad={hideLoader}
                        title="ricevimentomap"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.000281059629!2d14.084057777389214!3d40.78400797138291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133b126fe17b81fd%3A0x1a997cd531262001!2sHotel%20Cala%20Moresca!5e0!3m2!1sit!2sit!4v1717140172510!5m2!1sit!2sit"
                        width="100%" height="450" style={{ "border": 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Modal.Body>
            </Modal>
            <Container>
                <Row ref={scrollRef} className="tbs-first-row">
                    <Col><div className="pretitle">L'agenda dell'evento</div></Col>
                </Row>
                <Row>
                    <Col><div className="title">Non perderti l'agenda dell'evento</div></Col>
                </Row>
            </Container>
            <Container className="box-container" id="scrollElement">
                <Row>

                    <div ref={sliderRefAgenda} className="keen-slider" >
                        <div className="keen-slider__slide number-slide1">
                            <Col className="box-item">
                                <div>01</div>
                                <div>Cerimonia</div>
                                <div>Santuario Madonna della Speranza <br /> C.so V. Emanuele III, 224<br /> Marigliano (NA)</div>
                                <div>ORE 10:30</div>
                                <div><NavLink onClick={handleShowCerimonia}>Vai alla mappa</NavLink></div>
                            </Col>
                        </div>
                        <div className="keen-slider__slide number-slide2">
                            <Col className="box-item box-item-image cerimonia"><div></div></Col>
                        </div>
                        <div className="keen-slider__slide number-slide3">
                            <Col className="box-item">
                                <div>02</div>
                                <div>Ricevimento</div>
                                <div>Cala Moresca <br /> Via Faro, 44 <br /> Bacoli (NA)</div>
                                <div>ORE 13:00</div>
                                <div><NavLink onClick={handleShowRicevimento}>Vai alla mappa</NavLink></div>
                            </Col>
                        </div>
                        <div className="keen-slider__slide number-slide4">
                            <Col className="box-item box-item-image ricevimento"></Col>
                        </div>
                    </div>
                </Row>
            </Container>
        </Container>
    )
}
