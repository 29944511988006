import React, { useRef, useState } from 'react';
import { Button, Container, Row, Col, Nav, Form, FloatingLabel } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator'
import firstBackImage from '../../content/images/header-image.png'
import PublicLoader, { ILoaderRef } from './publicLoaderComponent';
import AlertComponent, { IAlertRef } from '../../../common/components/ts/Alert';
import { useAuth } from '../../../common/components/ts/AuthProvider';
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';

export default function PublicWizardThankYou() {
    const [contacts, setContacts] = useState({ email: "", phone: "" });
    const [validated, setValidated] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [contactsSent, setContactsSent] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const loaderRef = useRef<ILoaderRef>();
    const alertRef = useRef<IAlertRef>();
    const authContext = useAuth();
    const handleInputEmailChange = (event: any) => {
        setContacts({ ...contacts, email: event.target.value });
    };

    const handleInputPhoneChange = (event: any) => {
        setContacts({ ...contacts, phone: event.target.value });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        loaderRef?.current?.show();
        let emailFilled = contacts.email !== null && contacts.email !== undefined && contacts.email.trim() !== "";
        let phoneFilled = contacts.phone !== null && contacts.phone !== undefined && contacts.phone.trim() !== "";
        let isEmailInvalid = false;
        let isPhoneInvalid = false;
        if (emailFilled && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contacts.email)) {
            setEmailInvalid(true);
            isEmailInvalid = true;
        }
        else {
            setEmailInvalid(false);
            isEmailInvalid = false;
        }

        if (phoneFilled && !validator.isMobilePhone(contacts.phone)) {
            setPhoneInvalid(true);
            isPhoneInvalid = true;
        }
        else {
            setPhoneInvalid(false);
            isPhoneInvalid = false;
        }

        setValidated(true);
        if ((emailFilled || phoneFilled) && !isEmailInvalid && !isPhoneInvalid) {
            let config = {
                headers: { Authorization: `Bearer ${authContext.token}` }
            }
            const client = axios.create({
                baseURL: process.env.REACT_APP_API_BASE_URL
            });
            client.post(`/api/publicarea/contacts/${searchParams.get("r")}`, contacts, config)
                .then(response => {
                    loaderRef?.current?.hide();
                    setContactsSent(true);
                    return;
                })
                .catch(error => {
                    loaderRef?.current?.hide();
                    alertRef?.current?.show("danger", "Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                    setContactsSent(false);
                    return;
                });
        }
        else {
            setContactsSent(false);
            loaderRef?.current?.hide();
        }

    };
    let navigate = useNavigate();
    return (
        <div className="wrapper">
            <PublicLoader ref={loaderRef}></PublicLoader>
            <AlertComponent ref={alertRef}></AlertComponent>
            <Container fluid className="t-bg-first t-thank-you">
                <Container>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <div className="t-logo">
                                <div>Francesco</div>
                                <div>&</div>
                                <div>Marica</div>
                            </div>
                        </Col>
                        <Col sm={8} md={8} lg={8} className="t-home-nav">
                            <Nav activeKey="/home">
                                <Nav.Item>
                                    <Nav.Link className="t-link" onClick={() => {
                                        window.scrollTo({ top: (document?.querySelector('.t-bg-second') as HTMLElement)?.offsetTop, behavior: 'auto' });
                                    }}>LASCIA I TUOI CONTATTI</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Button onClick={() => navigate("/p")}>TORNA ALLA HOME</Button>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="t-first-img-container">
                    <Row>
                        <Col sm={12} md={8} lg={8}>
                            <div className="tfi-text">
                                <div>&nbsp;</div>
                                <div>GRAZIE!</div>
                                <div>
                                    <span>Ti ringraziamo per il tempo che ci hai dedicato compilando le schede invitati</span>
                                    <br />
                                    <span> Queste informazioni ci aiuteranno ad organizzare e gestire al meglio il ricevimento</span>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <div className="tfi-image"><img src={firstBackImage} alt="first-back" /></div>
                        </Col>
                    </Row>
                </Container>

            </Container>
            <Container fluid className="t-bg-second t-thank-you">
                <Container>
                    <Row className="tbs-first-row">
                        <Col><div className="pretitle">Resta aggiornato</div></Col>
                    </Row>
                    <Row>
                        <Col><div className="title">Lascia i tuoi contatti</div></Col>
                    </Row>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="tbs-first-row">
                            <Col className="subtitle">
                                <Form.Group as={Col} className="form-label-group" controlId={`email`}>
                                    <FloatingLabel controlId={`floatingemail`} label="Email">
                                        <Form.Control type="email" placeholder="Email" isInvalid={emailInvalid} disabled={contactsSent} onChange={handleInputEmailChange} />
                                        <Form.Control.Feedback type="invalid">
                                            Inserire una mail valida
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col className="subtitle">
                                <Form.Group as={Col} className="form-label-group" controlId={`email`}>
                                    <FloatingLabel controlId={`floatingemail`} label="Telefono">
                                        <Form.Control type="text" placeholder="Telefono" isInvalid={phoneInvalid} disabled={contactsSent} onChange={handleInputPhoneChange} />
                                        <Form.Control.Feedback type="invalid">
                                            Inserire un numero di telefono valido
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            {(() => {
                                if (!contactsSent) {
                                    return <Col>
                                        <Button className="t-alternate-button" type="submit"><span>Invia</span></Button>
                                    </Col>
                                } else {
                                    return <Col className="subtitle">
                                        <div>Contatti inviaiti !</div>
                                    </Col>
                                }
                            })()}

                        </Row>
                    </Form>
                </Container>
            </Container>
            <Container fluid className="t-bg-third t-thank-you">
                <Row>
                    <Col><div className="pretitle">Torna quando vuoi</div></Col>
                </Row>
                <Row>
                    <Col><div className="title">Hai dimenticato qualcosa?</div></Col>
                </Row>
                <Row>
                    <Col><div className="subtitle">
                        <span>Non temere! Il sito &egrave; sempre online e potrai tornare quando vuoi, per aggiungere altri partecipanti o anche solo per consultare le informazioni</span>
                        <br />
                        <span>Ti baster&agrave; solo scannerizzare il QR Code!</span></div></Col>
                </Row>
                <Row>
                    <Col>
                        <Button className="t-alternate-button" onClick={() => navigate("/p")}>TORNA ALLA HOME</Button>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}
