import { useAuth } from "../../../common/components/ts/AuthProvider";
//import { useNavigate } from "react-router-dom";
//import { useEffect } from 'react'
import { Navigate, useNavigate } from "react-router-dom";

const PublicLogin = ({ returnUrl }: any) => {    
    const auth = useAuth();    
    const navigate = useNavigate();
    auth.ccLoginAction(() => {        
        navigate(returnUrl);
    });
    //useEffect(() => {
    //    navigate(returnUrl);
    //});    
    return (
        <></>
    );
};

export default PublicLogin;