import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Table, Pagination } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

export enum CellType {
    BUILTIN,
    CUSTOM
}

export type TableConfiguration = {
    header: string,
    dataKey: string,
    type: CellType,
    grow: number,
    sortable: boolean,
    fixed : boolean,
    renderFunc?: ({ rowData, dataKey, onClick, ...props }: any) => JSX.Element,
    minWidth?: number ,
    width?: number 
}



interface IDataGridDisplayOptions {
    showLoader: () => void,
    hideLoader: () => void,
    useDataContext: () => [Array<any>, React.Dispatch<React.SetStateAction<Array<any>>>],
    usePageContext: () => [number, (page:number) => void],
    useTotalContext: () => [number, React.Dispatch<React.SetStateAction<number>>],
    useLimitContext: () => [number, (limit:number) => void],
    fetchData: (page: number, limit: number, sortCol?: string, sortType?: any) => void,    
    renderConfiguration: Array<TableConfiguration>
}
export default function DataGridDisplay(options: IDataGridDisplayOptions) {
    const [limit, setLimit] = options.useLimitContext();
    const [total, setTotal] = options.useTotalContext();
    const [page, setPage] = options.usePageContext()
    const [apiData,] = options.useDataContext();
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();

    const handleChangeLimit = (dataKey: any) => {
        setPage(1);
        setLimit(dataKey);
        options.fetchData(1, dataKey);
    };

    const handlePageChange = (page: any) => {
        setPage(page);
        options.fetchData(page, limit);
    };
    const handleSort = (sortCol: any, sortType: any) => {
        setSortColumn(sortCol);
        setSortType(sortType);
        options.fetchData(page, limit, sortCol, sortType);
    };
    
    useEffect(() => {
        options.fetchData(page, limit);
    }, [])

    return (
        <div style={{ backgroundColor: "white" }}>
            <Table data={apiData}
                affixHeader
                affixHorizontalScrollbar
                autoHeight={true}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSort}
            >
                {(() => {
                    const columns = [];
                    for (let config of options.renderConfiguration) {
                        columns.push(
                            <Column flexGrow={config.grow} sortable={config.sortable} width={config.width ?? 150} minWidth={config.minWidth ?? 150} fixed={config.fixed}>
                                <HeaderCell>{config.header}</HeaderCell>
                                {config.type === CellType.CUSTOM && config.renderFunc !== undefined ? <config.renderFunc dataKey={config.dataKey}/>: <Cell dataKey={config.dataKey} />}
                            </Column>
                        );
                    }
                    return columns;
                })()}                
            </Table>
            <div style={{ padding: 20 }}>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={['total', '-', 'limit', '|', 'pager']}
                    total={total}
                    limitOptions={[10, 30, 50]}
                    limit={limit}
                    activePage={page}
                    onChangePage={handlePageChange}
                    onChangeLimit={handleChangeLimit}
                />
            </div>
        </div>
    );
}