import React, { Ref, useEffect, useRef } from 'react';
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from "keen-slider/react"
import { useWizardContext } from './publicWizard'
import { Navigate, NavLink } from 'react-router-dom';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import '../css/public-theme.scss'

export default function PublicWizardNumberParticipants({showLoader}:any) {    
    const [numberOfParticipants, setnumberOfParticipants] = React.useState(2)
    let hapticSelectionStarted = false;
    const hapticFeedback = async () => {
        //await Haptics.impact({ style: ImpactStyle.Heavy });   
        await Haptics.selectionChanged();
    };
    const hapticSelectionStart = async (triggerChange: boolean) => {
        await Haptics.selectionStart();
        hapticSelectionStarted = true;
        if (triggerChange)
            await hapticFeedback();
    };
    const hapticSelectionEnd = async () => {
        await Haptics.selectionEnd();
        hapticSelectionStarted = false;
    };


    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        loop: false,
        mode: "free-snap",
        breakpoints: {
            "(min-width: 300px)": {
                slides: { perView: 3, spacing: 15, origin: "center" },
            },
            "(min-width: 768px)": {
                slides: { perView: 5, spacing: 15, origin: "center" },
            },
            "(min-width: 1366px)": {
                slides: { perView: 7, spacing: 20, origin: "center" },
            },
        },
        slides: {
            origin: "center",
            perView: 3,
            spacing: 15,
        },
        slideChanged(props) {
            hapticSelectionStarted ? hapticFeedback() : hapticSelectionStart(false);
            props.slides.forEach((s) => s.classList.remove("number-slide-active"));
            props.slides[props.track.details.rel].classList.add("number-slide-active");
            setnumberOfParticipants(
                Number.parseInt(
                    props.slides.filter(slide => {
                        return slide.classList.contains("number-slide-active")
                    })[0].getAttribute("data-value")?.toString() ?? "0"
                )
            );
        }
    })

    const openLoader = () => {
        showLoader();
    }

    useEffect(() => {
        instanceRef.current?.moveToIdx(1);
        return function cleanup() {
            hapticSelectionEnd();
        }
    }, [instanceRef]);


    return (
        <>            
            <div className="wrapper">
                <div className="container">
                    <div className="f-bigrow">
                        <div className="f-row f-jc">
                            <p className="text-center low-margin pretitle">FESTEGGIAMO INSIEME</p>
                        </div>
                        <div className="f-row f-jc">
                            <p className="text-center low-margin title">CONFERMA LA TUA <br /> PRESENZA</p>
                        </div>
                        <div className="f-row f-jc">
                            <p className="text-center low-margin subtitle">Indicaci il numero di partecipanti</p>
                        </div>
                    </div>
                </div>
                <div className="slider-container">
                    <div className="slider-highlight"></div>
                    <div ref={sliderRef} className="keen-slider">
                        {(() => {
                            const numbers = [];
                            for (let i = 1; i <= 10; i++) {
                                numbers.push(<div key={i} data-value={i} className="keen-slider__slide number-slide">{i}</div>)
                            }
                            return numbers;
                        })()}
                    </div>
                </div>
            </div>
            <div className="slider-button">
                <NavLink className="btn t-alternate-button" to="/p/wizard" onClick={openLoader} state={{ numberOfParticipants: numberOfParticipants, listOfGuest: [] }} >Avanti</NavLink>
            </div>
        </>
    )
}
