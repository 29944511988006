import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row} from "react-bootstrap";
import { Table, Button } from "rsuite";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";
const { Column, HeaderCell, Cell } = Table;

function AdminDatabaseManage() {
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const [listData, setListData] = useState<any>();
    const authContext = useAuth();

    useEffect(() => {        
        setSectionName("Impostazioni");
        fetchListData();
    }, [])

    const fetchListData = () => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/settings/backup/list", config)
            .then(response => {
                if (response.data) {
                    setListData(response.data);
                }
                hideLoader();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const makeBackup = () => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post("/api/settings/backup", {}, config)
            .then(response => {
                fetchListData();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const deleteDatabase = (dbname: string) => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.delete(`/api/settings/backup/${dbname}`, config)
            .then(response => {                
                hideLoader();
                fetchListData();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const downloadDatabase = (dbname: string) => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            responseType: 'blob'
        });
        client.get(`/api/settings/backup/${dbname}`, config)
            .then(response => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${dbname}`); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                hideLoader();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const ActionCell = ({ rowData, dataKey, onClick, ...props }: any) => {
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                <Button
                    appearance="link"
                    onClick={() => {                        
                        downloadDatabase(rowData.name);                        
                    }}
                >
                    Download
                </Button>
            </Cell>
        );
    };

    const DeleteCell = ({ rowData, dataKey, onClick, ...props }: any) => {
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                <Button
                    appearance="link"
                    onClick={() => {
                        deleteDatabase(rowData.name);
                    }}
                >
                    Elimina
                </Button>
            </Cell>
        );
    };


  return (
      <Container fluid className="admin-main-content">
          <Row className="button" style={{ marginBottom: "12px" }}>
              <Col style={{ textAlign: "right" }}>
                  <Button className="t-primary-button" onClick={() => {                      
                      makeBackup();                      
                  }}><>Esegui backup</>
                  </Button></Col>
          </Row>
          <Table height={420} data={listData}
              autoHeight={true}
              affixHorizontalScrollbar
          >
              <Column flexGrow={1} minWidth={150}>
                  <HeaderCell>Nome</HeaderCell>
                  <Cell dataKey="name" />
              </Column>             
              <Column flexGrow={1} minWidth={150} >
                  <HeaderCell>&nbsp;</HeaderCell>
                  <ActionCell dataKey="name" />
              </Column>
              <Column flexGrow={1} minWidth={150} >
                  <HeaderCell>&nbsp;</HeaderCell>
                  <DeleteCell dataKey="name" />
              </Column>

          </Table>
      </Container>
  );
}

export default AdminDatabaseManage;