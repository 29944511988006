import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Divider, SelectPicker } from "rsuite";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";
import DataGridDisplay, { CellType, TableConfiguration } from "./dataGridDisplay";
import DataGridFilters, { DataGridFiltersFieldType, DataGridFiltersProps } from "./dataGridFilters";
import { Table, Modal as RModal } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import { useNavigate } from "react-router-dom";
import { renderToString } from 'react-dom/server';
import { ParticipantAge, ParticipantMenuChoice } from "../../common/types/Participant";
const { Cell } = Table;

function AdminTableauList() {
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const [apiData, setApiData] = useState<any>();
    const [filterTables, setFilterTables] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(parseInt(window.sessionStorage.getItem("grid_tableau_page") || "1"));
    const [limit, setLimit] = useState(parseInt(window.sessionStorage.getItem("grid_tableau_limit") || "10"));
    const [filterName, setFilterName] = useState(window.sessionStorage.getItem("grid_tableau_filterName") || "");
    const [filterSurname, setFilterSurname] = useState(window.sessionStorage.getItem("grid_tableau_filterSurname") || "");
    const [filterTableId, setFilterTableId] = useState(window.sessionStorage.getItem("grid_tableau_filterTableId") || "");
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);    
    const [currentTableId, setCurrentTableId] = useState(0);
    const [currentGuestId, setCurrentGuestId] = useState(0);
    const authContext = useAuth();
    /*const navigate = useNavigate();*/

    const handleAssignHide = () => {
        setCurrentGuestId(0);
        setCurrentTableId(0);
        setShowAssignModal(false);
    }

    const handleRemoveHide = () => {
        setCurrentGuestId(0);
        setCurrentTableId(0);
        setShowRemoveModal(false);
    }

    const handleRemoveConfirm = () => {
        removeGuest();
    }

    useEffect(() => {
        setSectionName("Lista Tavoli");
        fetchFilterData();
    }, [])

    const setPageSession = (page: number) => {
        window.sessionStorage.setItem("grid_tableau_page", page.toString());
        setPage(page);
    }
    const setLimitSession = (limit: number) => {
        window.sessionStorage.setItem("grid_tableau_limit", limit.toString());
        setLimit(limit);
    }
    const setFilterNameSession = (name: string) => {
        setFilterName(name);
        window.sessionStorage.setItem("grid_tableau_filterName", name);
    }
    const setFilterTableIdSession = (value: number | null) => {
        setFilterTableId(value?.toString() || "");
        window.sessionStorage.setItem("grid_tableau_filterTableId", value?.toString() || "");
    }
    const setFilterSurnameSession = (value: string) => {
        setFilterSurname(value);
        window.sessionStorage.setItem("grid_tableau_filterSurname", value);
    }

    const renderConfig: Array<TableConfiguration> = [
     
        { header: "Nome", dataKey: "name", grow: 1, sortable: true, type: CellType.BUILTIN, fixed: false },
        { header: "Cognome", dataKey: "surname", grow: 1, sortable: true, type: CellType.BUILTIN, fixed: false },
        {
            header: renderToString(<>Et&agrave;</>), dataKey: "age", grow: 1, sortable: true, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {rowData.age === ParticipantAge.Adulto ? 'ADULTO' : 'BAMBINO'}
                    </Cell>
                );
            }
        },
        {
            header: renderToString(<>Tavolo</>), dataKey: "table", grow: 1, sortable: true, type: CellType.CUSTOM, fixed: false,
            renderFunc: ({ rowData, dataKey, onClick, ...props }: any) => {
                return (
                    <Cell {...props} style={{ padding: '6px' }}>
                        {rowData.table?.name === null || rowData.table?.name === undefined? <>
                            <Button variant="link"
                                onClick={() => {
                                    setCurrentGuestId(rowData.id);
                                    setShowAssignModal(true);
                                }}
                            >      Assegna
                            </Button>
                        </> :
                            <>
                                {rowData.table.name}
                                <Button variant="link"
                                    onClick={() => {
                                        setCurrentGuestId(rowData.id);
                                        setCurrentTableId(rowData.table.id)
                                        setShowRemoveModal(true);
                                    }}
                                >      Rimuovi
                                </Button>
                            </>
                        }
                    </Cell>
                );
            }
        }

    ]

    const fetchData = (page: number, limit: number, sortCol?: string, sortType?: any) => {
        showLoader();
        let config = {
            params: {
                name: filterName,
                surname: filterSurname,
                tableId: filterTableId,
                page: page,
                pageSize: limit,
                sortColumn: sortCol,
                sortDirection: sortType
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/tableau/tables/guests/list", config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    setApiData(response.data.items);
                    setPage(response.data.page);
                    setTotal(response.data.totalCount);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const fetchFilterData = () => {
        showLoader();
        let config = {
            params: {
                includeGuests: false
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/tableau/tables", config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    setFilterTables(response.data.map((item: any) => ({ label: item.name, value: item.id })));
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const filterConfig: DataGridFiltersProps = {
        fields: [{
            name: "Nome",
            type: DataGridFiltersFieldType.TEXT,
            fieldContext: [filterName, setFilterNameSession]
        },
        {
            name: "Cognome",
            type: DataGridFiltersFieldType.TEXT,
            fieldContext: [filterSurname, setFilterSurnameSession]
        },
        {
            name: "Tavolo",
            type: DataGridFiltersFieldType.CHOICE,
            fieldContext: [filterTableId, setFilterTableIdSession],
            choiceOptions:
                [
                    { label: "Tutto", value: "" },
                    ...filterTables
                ]
        }
        ],
        onSearch: () => fetchData(1, limit),
        onClear: () => {
            setFilterNameSession("");
            setFilterSurnameSession("");
            setFilterTableIdSession(0)
        },
        onNew: () => { },
        onExport: () => {
            showLoader();
            let date = new Date();
            let config = {
                params: {
                },
                headers: { Authorization: `Bearer ${authContext.token}` }
            }
            const client = axios.create({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                responseType: 'blob'
            });
            client.get(`/api/tableau/export`, config)
                .then(response => {                    
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `Export_Tavoli_${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.docx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    hideLoader();
                    return;
                })
                .catch(error => {
                    hideLoader();
                    showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                    return;
                });
        },
        hideNew: true
    }

    const removeGuest = () => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.delete(`/api/tableau/tables/${currentTableId}/guests/${currentGuestId}`, config)
            .then(response => {
                hideLoader();
                fetchData(1, limit);
                setCurrentGuestId(0);
                setCurrentTableId(0);
                setShowRemoveModal(false);
                showAlert("", <>Operazione eseguita correttamente</>, "success");
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const addGuest = () => {
        showLoader();
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/tableau/tables/${currentTableId}/guests`, [currentGuestId], config)
            .then(response => {
                hideLoader();
                setCurrentGuestId(0);
                setCurrentTableId(0);
                fetchData(1, limit);
                setShowAssignModal(false);
                showAlert("", <>Operazione eseguita correttamente</>, "success");
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    return (
        <Container fluid className="admin-main-content">
            <Modal show={showRemoveModal} onHide={handleRemoveHide} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Attenzione</Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    {(() => {
                        let guest = apiData?.find((itm: any) => { return itm.id === currentGuestId });
                        return <p>Confermare la rimozione di <strong>{guest?.name?.toUpperCase()} {guest?.surname?.toUpperCase()}</strong> dal tavolo <strong>{guest?.table?.name}</strong>? L'operazione non pu&ograve; essere annullata</p>;
                    })()}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="t-alternate-button" onClick={handleRemoveHide}>
                        Annulla
                    </Button>
                    <Button className="t-primary-button danger" onClick={handleRemoveConfirm} >Elimina</Button>
                </Modal.Footer>
            </Modal>
            <RModal open={showAssignModal} onClose={handleAssignHide} backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter">
                <RModal.Header closeButton>
                    <RModal.Title>Seleziona Tavolo</RModal.Title>
                </RModal.Header>
                <RModal.Body>
                    <Row>
                        <Col>
                            <SelectPicker data={filterTables} onSelect={(value: number) => { setCurrentTableId(value); }} style={{ width: 300 }}></SelectPicker>
                        </Col>
                        <Col>
                            <Button className="t-primary-button" onClick={() => { addGuest(); }} >Aggiungi</Button>
                        </Col>
                    </Row>
                   
                </RModal.Body>
                <RModal.Footer>
                </RModal.Footer>
            </RModal>
            <DataGridFilters {...filterConfig}></DataGridFilters>
            <Divider></Divider>
            <DataGridDisplay
                showLoader={showLoader}
                hideLoader={hideLoader}
                fetchData={fetchData}
                useDataContext={() => [apiData, setApiData]}
                usePageContext={() => [page, setPageSession]}
                useTotalContext={() => [total, setTotal]}
                useLimitContext={() => [limit, setLimitSession]}
                renderConfiguration={renderConfig}
            ></DataGridDisplay>
        </Container>
    );
}

export default AdminTableauList;