import * as React from "react";
import { Dispatch } from "react";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import { ParticipantListItem } from "../../../common/types/Participant";

type WizardContextType = {
    guestsNumber: number;
    listOfGuests?: ParticipantListItem[] | null
}



export enum WizardContextReducerAction {
    CHANGE_NUMBER = "change_guestnumber",
    CHANGE_PARTICIPANT = "change_participant",
    CHANGE_NAME = "changed_name",
    CHANGE_SURNAME = "changed_surname",
    CHANGE_AGE = "changed_age",
    CHANGE_ALLERGY = "changed_allergy",
    CHANGE_OTHER_ALLERGY = "changed_other_allergy",
    CHANGE_UNFAVOR = "changed_unfavor",
    CHANGE_MENU_CHOICE = "changed_menu_choice",
    ADD_ALLERGY = "added_allergy",
    ADD_UNFAVOR = "added_unfavor"
}

export default function PublicWizard() {
    const navigate = useNavigate();
    const location = useLocation();
    //const initialState = JSON.parse(localStorage.getItem("tjb_wizardcontext") || `{ "guestsNumber": ${location.state.numberOfParticipants}, "listOfGuests": [] }`);    
    const initialState = { guestsNumber: location.state.numberOfParticipants, listOfGuests: [] };    
    const [wizardContext, dispatchWizardContext] = React.useReducer(wizardContextReducer, initialState);

    React.useEffect(() => {        
        navigate("/p/wizard/list", { state: location.state })        
    }, []);

    return (
        <>
            <div>
                <Outlet context={[wizardContext, dispatchWizardContext]} />
            </div>
        </>
    )
}

export function useWizardContext() {
    return useOutletContext<[WizardContextType, Dispatch<any>]>();
}
function wizardContextReducer(state: any, action: any) {
    switch (action.type) {
        case WizardContextReducerAction.CHANGE_NUMBER: {
            let result = {
                ...state,
                guestsNumber: action.nextGuestNumber
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_PARTICIPANT: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            if (list[action.participantIndex] === undefined) {
                list.push(action.nextParticipant)
            } else {
                list[action.participantIndex] = action.nextParticipant;
            }

            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_NAME: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            list[action.participantIndex].name = action.nextName;
            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_SURNAME: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            list[action.participantIndex].surname = action.nextSurname;
            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_AGE: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            list[action.participantIndex].age = action.nextAge;
            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_MENU_CHOICE: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            list[action.participantIndex].menuChoice = action.nextMenuChoice;
            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_ALLERGY: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            list[action.participantIndex].allergies = action.nextAllergies;
            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_OTHER_ALLERGY: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            list[action.participantIndex].otherAllergy = action.nextOtherAllergy;
            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
        case WizardContextReducerAction.CHANGE_UNFAVOR: {
            let list: ParticipantListItem[] = [...state.listOfGuests!];
            list[action.participantIndex].unfavorability = action.nextUnfavorability;
            let result = {
                ...state,
                listOfGuests: list
            };
            localStorage.setItem("tjb_wizardcontext", JSON.stringify(result));
            return result;
        }
    }
}