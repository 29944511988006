import { useContext, createContext, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

interface IAuthProviderValue {
    token?: any,
    user?: any,
    ccLoginAction?: any,
    authorize?: any,
    manageCallback?: any,
    logOut?: any,
    tryRefreshToken?: any
}

export interface ISiteUser {    
    access_token?: string | null,
    refresh_token? :string | null,
    id_token?: string | null,
    token_type?: string | null,
    expires_in?: number | null,
    error? : string | null,
    hasError?: boolean | null
}

const AuthContext = createContext<IAuthProviderValue>({});

const AuthProvider = ({ children }: any) => {
    let localUser = {} as ISiteUser;
    try {
        localUser = JSON.parse(localStorage.getItem("site_user") ?? "") as ISiteUser;
    }
    catch {
        localStorage.removeItem("site_token");
        localStorage.removeItem("site_user");
    }
    
    const [user, setUser] = useState<ISiteUser>(localUser);
    const [token, setToken] = useState(localStorage.getItem("site_token") || "");
    const navigate = useNavigate();

    //const [adminUser, setAdminUser] = useState(localStorage.getItem("site_user_admin") || {});

    const ccLoginAction = async (callback: any) => {        
        const client = axios.create({
            baseURL: process.env.REACT_APP_AUTH_AUTHORITY
        });
        let authRequest = {
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            code: "",
            grant_type: "client_credentials",
            scope: process.env.REACT_APP_AUTH_SCOPE,
            client_secret: process.env.REACT_APP_AUTH_SECRET,
            redirect_Uri: process.env.REACT_APP_AUTH_REDIRECT_URI
        };
        client.post("/oauth/token", authRequest)
            .then(response => {
                setUser(response.data);
                setToken(response.data.access_token);
                localStorage.setItem("site_token", response.data.access_token);
                localStorage.setItem("site_user", JSON.stringify(response.data));
                callback();
                return;
                //here we should set up context
            })
            .catch(error => {
                navigate("/Unauthorized");
            });
    };

    const authorize = async () => {
        const params = new URLSearchParams({
            client_id: `${process.env.REACT_APP_ADMIN_AUTH_CLIENT}`,
            response_type: "code",
            redirect_uri: `${process.env.REACT_APP_ADMIN_AUTH_CALLBACK_URI}`,
            scope: `${process.env.REACT_APP_ADMIN_AUTH_SCOPES}`
        });
        window.location.href = `${process.env.REACT_APP_AUTH_AUTHORITY}/oauth/authorize?${createSearchParams(params)}`;        
        return;
    };

    const manageCallback = (code: string) => {
        const client = axios.create({
            baseURL: process.env.REACT_APP_AUTH_AUTHORITY
        });
        let authRequest = {
            client_id: process.env.REACT_APP_ADMIN_AUTH_CLIENT,
            code: code,
            grant_type: "code",
            scope: process.env.REACT_APP_ADMIN_AUTH_SCOPES,
            client_secret: process.env.REACT_APP_ADMIN_AUTH_SECRET,
            redirect_Uri: process.env.REACT_APP_ADMIN_AUTH_CALLBACK_URI
        };
        client.post("/oauth/token", authRequest)
            .then(response => {
                if (response.data.error) {
                    navigate("/Unauthorized");
                    return;
                }
                setUser(response.data);
                setToken(response.data.access_token);
                localStorage.setItem("site_token", response.data.access_token);
                localStorage.setItem("site_user", JSON.stringify(response.data));
                navigate("/admin");
                return;
                //here we should set up context
            })
            .catch(error => {
                navigate("/Unauthorized");
            });
    }

    const tryRefreshToken = () => {
        const client = axios.create({
            baseURL: process.env.REACT_APP_AUTH_AUTHORITY
        });
        let authRequest = {
            access_token: user.access_token,
            refresh_token: user.refresh_token
        };
        client.post("/oauth/refresh-token", authRequest)
            .then(response => {
                setUser({ ...user, access_token: response.data.access_token, refresh_token: response.data.refresh_token });
                setToken(response.data.access_token);
                localStorage.setItem("site_token", response.data.access_token);
                localStorage.setItem("site_user", JSON.stringify({ ...user, access_token: response.data.access_token, refresh_token: response.data.refresh_token }));
                return;
                //here we should set up context
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const logOut = () => {
        setUser({});
        setToken("");
        //setAdminUser({});
        localStorage.removeItem("site_token");
        localStorage.removeItem("site_user");            
    };

    return (
        <AuthContext.Provider value={{ token, user, ccLoginAction, authorize, manageCallback, logOut, tryRefreshToken } as IAuthProviderValue}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};