import { Sidenav, Nav, Avatar, Divider } from 'rsuite';
import DashboardIcon from '@rsuite/icons/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import ExitIcon from '@rsuite/icons/Exit';
import TableColumnIcon from '@rsuite/icons/TableColumn';
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../../common/components/ts/AuthProvider';
import { jwtDecode } from 'jwt-decode';
import ImageIcon from '@rsuite/icons/Image';
function AdminSidenav({ isExpanded }: any) {
    const auth = useAuth();
    const idToken = jwtDecode(auth.user.id_token);
    console.log(idToken);
    const [activeKey, setActiveKey] = useState('1');
    const navigate = useNavigate();
    const location = useLocation();
    const onSelect = (eventKey: string | undefined) => {
        document.querySelectorAll('.rs-sidenav-item').forEach((i) => { i.classList.remove('rs-sidenav-item-active') });
        if (eventKey)
            navigate(eventKey.toString());
    }


    useEffect(() => {
        document.querySelectorAll('.rs-sidenav-item').forEach((i) => { i.classList.remove('rs-sidenav-item-active') });
        let lastPath = location.pathname.split("/").pop();
        if (lastPath === "admin") {
            navigate("dashboard");
            lastPath = "dashboard";
        }
        document.querySelector(`[data-event-key="${lastPath}"]`)?.classList.add('rs-sidenav-item-active');

    }, [])

    return (

        <Sidenav expanded={isExpanded} defaultOpenKeys={[]}>
            <Sidenav.Header>
                <div className="t-logo">
                    <div>{process.env.REACT_APP_HIM_NAME}</div>
                    <div>&</div>
                    <div>{process.env.REACT_APP_HER_NAME}</div>
                </div>
                <div className="t-logo collapsed">
                    <div>{process.env.REACT_APP_HIM_START}</div>
                    <div>&</div>
                    <div>{process.env.REACT_APP_HER_START}</div>
                </div>
                <Divider />
                <Row className="sidenav-logout">
                    <Row>
                        <Col>
                            <Avatar circle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {(idToken as any).given_name}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <NavLink className="logout-link" to="/logout">Logout</NavLink>
                        </Col>
                    </Row>
                </Row>
                <Row className="sidenav-logout collapsed">
                    <Row>
                        <Col>
                            <Avatar circle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <NavLink className="logout-link" to="/logout"><ExitIcon /></NavLink>
                        </Col>
                    </Row>
                </Row>
                <Divider />

            </Sidenav.Header>
            <Sidenav.Body>
                <Nav activeKey={activeKey} onSelect={setActiveKey}>
                    <Nav.Item eventKey="dashboard" icon={<DashboardIcon />} onSelect={onSelect}>
                        Dashboard
                    </Nav.Item>
                    <Nav.Item eventKey="guestList" icon={<GroupIcon />} onSelect={onSelect}>
                        Lista Partecipanti
                    </Nav.Item>
                    <Nav.Menu
                        placement="rightStart"
                        eventKey="5"
                        title="Tableau"
                        icon={<TableColumnIcon />}
                    >
                        <Nav.Item eventKey="tableau-manage" onSelect={onSelect}>Gestisci</Nav.Item>
                        <Nav.Item eventKey="tableau-search" onSelect={onSelect}>Elenco</Nav.Item>
                    </Nav.Menu>
                    <Nav.Item eventKey="gallery" icon={<ImageIcon />} onSelect={onSelect}>
                        Gallery
                    </Nav.Item>
                    <Nav.Menu
                        placement="rightStart"
                        eventKey="4"
                        title="Impostazioni"
                        icon={<GearCircleIcon />}
                    >
                        <Nav.Item eventKey="settings" onSelect={onSelect}>Invitati</Nav.Item>
                        <Nav.Item eventKey="database" onSelect={onSelect}>Database</Nav.Item>
                    </Nav.Menu>
                </Nav>
            </Sidenav.Body>
        </Sidenav>
    );
}

export default AdminSidenav;