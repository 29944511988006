export type GuestListItem = {
    id: number|null,
    name: string | null,
    surname: string | null,
    age: ParticipantAge | null
    allergies: ParticipantAllergy[],
    unfavorability: ParticipantUnfavorability[],
    otherAllergy: string | null,
    menuChoice: ParticipantMenuChoice | null
}

export type ParticipantListItem = {
    name: string | null,
    surname: string | null,
    age: ParticipantAge | null
    allergies: ParticipantAllergy[],
    unfavorability: ParticipantUnfavorability[],
    otherAllergy: string | null,
    menuChoice: ParticipantMenuChoice | null
}

export enum ParticipantAge {
    Adulto = 1,
    Bambino = 2
}
export enum ParticipantMenuChoice {
    ADULTO = 1,
    BAMBINO = 2,
    BAMBINO_MOD = 3
}
export type ParticipantAllergy = {
    allergy: string | null,
    hasAllergy: boolean | null
}

export type ParticipantUnfavorability = {
    unfavorability: string | null,
    hasUnfavorability: boolean | null
}