import { Dispatch, SetStateAction, useRef } from "react";
import { Col, Row } from 'react-bootstrap';
import '../css/adminApp.scss';
import { Outlet, useOutletContext } from 'react-router-dom';
import AdminSidenav from './adminSidenav';
import { IconButton, Loader } from 'rsuite';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import { useState } from 'react';
import AdminAuth from './AdminAuth';
import AdminLoader, { IAdminLoaderRef } from "./AdminLoader";
import AlertComponent, { IAlertRef } from '../../common/components/ts/Alert';

export default function AdminApp() {
    const [expanded, setExpanded] = useState(false);
    const [menuIcon, setMenuIcon] = useState(<ArrowLeftLineIcon />);
    const [sectionName, setSectionaName] = useState("");
    const loaderRef = useRef<IAdminLoaderRef>();
    let alertRef = useRef<IAlertRef>();
    const showLoader = () => {
        loaderRef?.current?.show();
    }
    const hideLoader = () => {
        loaderRef?.current?.hide();
    }
    const showAlert = (title: string, content: JSX.Element, type:string = "danger") => {
        alertRef?.current?.show(type, title, content);
    }
    return (
        <AdminAuth>
            <div className={'main-wrapper'} >
                <AdminSidenav isExpanded={expanded}></AdminSidenav>
                <main className="main-container">
                    <Row className="admin-section-header">
                        <Col className="admin-section-menu-toggle">
                            <IconButton icon={menuIcon} onClick={() => { if (!expanded) setMenuIcon(<ArrowLeftLineIcon />); else setMenuIcon(<ArrowRightLineIcon />); setExpanded(!expanded); }} />
                        </Col>
                        <Col className="admin-section-name">
                            {sectionName}
                        </Col>
                    </Row>
                    <Outlet context={[sectionName, setSectionaName, showLoader, hideLoader, showAlert]}></Outlet>
                </main>
            </div>
            <AdminLoader ref={loaderRef} props={{ noAutoHide: true }}></AdminLoader>
            <AlertComponent ref={alertRef} ></AlertComponent>
        </AdminAuth>
    );
}

export function useSectionNameContext() {
    return useOutletContext<[string, Dispatch<SetStateAction<string>>, () => void, () => void, (t: string, c: JSX.Element, type?: string) => void]>();
}