import { forwardRef, useImperativeHandle, useEffect, HTMLProps, MutableRefObject } from "react";

export interface ILoaderRef {
    show(): any,
    hide(): any
}

const PublicLoader = forwardRef((props: any, ref) => {

    useImperativeHandle(ref, () => ({
        show() {
            document.querySelector(".loader")?.classList.add("shown");
            window.scrollTo({ top: 0, behavior: 'auto' });
        },
        hide() {
            document.querySelector(".loader")?.classList.remove("shown");
        }
    })
    );

    useEffect(() => {
        if (props?.props?.noAutoHide === undefined || !props.props.noAutoHide) {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'auto' });
                document.querySelector(".loader")?.classList.remove("shown");
            }, 100);
        }

    }, [])

    return (
        <div className="loader shown" style={{ "backgroundColor": props?.props?.backgroundColor || "rgba(255,255,255,0.5)" } }>
            <div className="lds-heart"><div></div></div>
        </div>
    );
})

export default PublicLoader;