import axios from "axios";
import { blob } from "node:stream/consumers";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";

type GalleryRequest = {
    requestId?: number | null,
    group?: string | null,
    items?: GalleryRequestItem[] | null
}

type GalleryRequestItem = {
    id: number,
    name: string,
    sasUri: string,
    thumbSasUri: string,
    status: string
}

type BlobStatus = {
    name?: string | null,
    status?: string | null
}

function AdminGalleryDetail() {
    const { galleryId } = useParams();
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const authContext = useAuth();
    //const [showDelete, setShowDelete] = useState(false);
    //const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [gallery, setGallery] = useState<GalleryRequest>({} as GalleryRequest);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    let blobStatusTrack = Array<BlobStatus>();

    const fetchGalleryById = (newUserId?: string) => {
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get(`/api/Gallery/requests/${galleryId}`, config)
            .then(response => {
                hideLoader();
                if (response.data) {
                    setGallery(response.data);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const generateThumb = (imageId?: number) => {
        showLoader();
        let config = {
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.post(`/api/Gallery/requests/${galleryId}/generate`, [imageId], config)
            .then(response => {
                hideLoader();
                //if (response.data) {
                //    setGallery(response.data);
                //}
                fetchGalleryById();
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Oh no!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const handleSubmit = (event: any) => {
        showLoader();
        const form = event.currentTarget;
        let formValid = form.checkValidity();
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
        if (formValid) {
            //saveGallery();
        }
        else {
            hideLoader();
        }
    };
    const handleApproveSwitchChange = (event: any) => {
        let itemName = event.target.getAttribute("data-item");
        if (gallery?.items !== null && gallery?.items !== undefined) {
            let propIndex = gallery.items.findIndex(x => x.name === itemName);
            let prevItems = [...gallery.items];
            prevItems[propIndex].status = event.target.checked ? "approved" : "pending";
            setGallery({ ...gallery, items: prevItems })
        }

    }
    useEffect(() => {
        showLoader();
        setSectionName("Dettaglio Gallery");
        fetchGalleryById();
    }, [])


    return (
        <Container fluid className="admin-main-content t-guest-data">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    {(() => {
                        let images = [];
                        if (gallery?.items !== undefined && gallery?.items !== null) {
                            for (let blob of gallery?.items) {
                                images.push(<>
                                    <Col lg={2}><Card>
                                        <Card.Body>

                                            <Row><img style={{ width: "100%" }} src={blob.thumbSasUri} alt={blob.name} /></Row>
                                            <Row style={{ marginTop: "16px" }}>
                                                <Col>

                                                    <Form.Check
                                                        type="switch"
                                                        id={`approve-switch${blob.id.toString()}`}
                                                        key={`approve-switch${blob.id.toString()}`}
                                                        label="Approva"
                                                        data-item={blob.name}
                                                        onChange={handleApproveSwitchChange}
                                                        checked={blob.status !== null && blob.status !== undefined && blob.status === "approved"}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Button
                                                        variant="link"
                                                        onClick={() => { generateThumb(blob.id) }}
                                                    >
                                                        Genera Thumb
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    </Col>
                                </>)
                            }
                        }
                        return images;
                    })()}
                </Row>
                <Row className="button">
                    <Col>
                        <Button type="submit" className="t-primary-button">Salva</Button>
                    </Col>
                </Row >
            </Form>
        </Container>
    );
}

export default AdminGalleryDetail;