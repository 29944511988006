import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../../common/components/ts/AuthProvider";
import '../css/publicarea.css';

const PublicAuth = () => {
    const user = useAuth();
    if (user.token === undefined || !user.token) return <Navigate to="/Unauthorized" />;
    return <Outlet />;
};

export default PublicAuth;