import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../common/components/ts/AuthProvider";
import { useSectionNameContext } from "./adminApp";
import { Table, Button, Divider } from 'rsuite';
import axios from "axios";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ParticipantAge } from "../../common/types/Participant";
import Chart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import commonExports from "../../common/css/common.module.scss"
import ReloadIcon from '@rsuite/icons/Reload';
const { Column, HeaderCell, Cell } = Table;

type ChartData = {
    numberOfGuests: number,
    expectedNumberOfGuests: number,
    adultGuests: number,
    childGuests: number,
    guestsWithAllergies: number,
    guestsWithPreferences: number,
    childMenusModded: number,
    progress: number[]
}

function AdminDashboard() {
    const [, setSectionName, showLoader, hideLoader, showAlert] = useSectionNameContext();
    const [chartData, setChartData] = useState<ChartData>({
        progress: [0.0],
        numberOfGuests: 0,
        expectedNumberOfGuests: 0,
        adultGuests: 0,
        childGuests: 0,
        guestsWithAllergies: 0,
        guestsWithPreferences: 0,
        childMenusModded: 0,
    });
    const [listData, setListData] = useState<any>();
    const authContext = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        showLoader();
        setSectionName("Dashboard");
        fetchChartData();
        fetchListData();
    }, [])

    const fetchChartData = () => {        
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/Analytics/charts", config)
            .then(response => {
                if (response.data) {
                    console.log(response.data);
                    setChartData({
                        progress: [response.data.progress],
                        numberOfGuests: response.data.numberOfGuests,
                        expectedNumberOfGuests: response.data.expectedNumberOfGuests,
                        adultGuests: response.data.adultGuests,
                        childGuests: response.data.childGuests,
                        guestsWithAllergies: response.data.guestsWithAllergies,
                        guestsWithPreferences: response.data.guestsWithPreferences,
                        childMenusModded: response.data.childMenusModded
                    });
                } 
                hideLoader();
                return;
            })
            .catch(error => {    
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }

    const fetchListData = () => {        
        let config = {
            params: {
            },
            headers: { Authorization: `Bearer ${authContext.token}` }
        }
        const client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
        });
        client.get("/api/analytics/lastRegistrations", config)
            .then(response => {                
                if (response.data) {
                    setListData(response.data);
                }
                return;
            })
            .catch(error => {
                hideLoader();
                showAlert("Ops!", <><div>Si &egrave; verificato un problema. Riprova!</div><div>{error.code}</div></>);
                return;
            });
    }


    const ActionCell = ({ rowData, dataKey, onClick, ...props }: any) => {
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                <Button
                    appearance="link"
                    onClick={() => {
                        navigate(`/admin/guests/${rowData.id}`);
                    }}
                >
                    Vai alla scheda
                </Button>
            </Cell>
        );
    };

    const DateCell = ({ rowData, dataKey, onClick, ...props }: any) => {
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                {new Date(rowData.creationDate).toLocaleString()}
            </Cell>
        );
    };

    const MenuCell = ({ rowData, dataKey, onClick, ...props }: any) => {
        return (
            <Cell {...props} style={{ padding: '6px' }}>
                {rowData.age === ParticipantAge.Adulto ? 'ADULTO' : 'BAMBINO'}
            </Cell>
        );
    };

    return (
        <>
            <Container fluid className="admin-main-content">
                <Row className="button" style={{marginBottom:"12px"} }>
                    <Col style={{ textAlign: "right" }}>
                        <Button className="t-primary-button" onClick={() => {
                            showLoader();
                            fetchChartData();
                            fetchListData();
                        }}><><ReloadIcon></ReloadIcon>&nbsp; Aggiorna</>
                        </Button></Col>
                </Row>

                <Row>
                    <Col xs={12} md={12}  lg={4}>
                        <Card>
                            <Card.Body>
                                <Chart type="radialBar" height="280"                                
                                    series={chartData.progress}
                                    options={{                                        
                                        plotOptions: {
                                            radialBar: {
                                                hollow: {
                                                    margin: 20,
                                                    size: "60%"
                                                },
                                                dataLabels: {
                                                    name: {
                                                        offsetY: -10,
                                                        show: true,
                                                        color: "#888",
                                                        fontSize: "13px"
                                                    },
                                                    value: {
                                                        color: "#111",
                                                        fontSize: "30px",
                                                        show: true
                                                    }
                                                }
                                            }
                                        },

                                        stroke: {
                                            lineCap: "round",
                                        },
                                        labels: [renderToString(<>Invitati registrati</>)],
                                        colors: [commonExports.primaryChartColor],                                        
                                    }}></Chart>
                                <Row>
                                    <Col style={{ textAlign: "center" }}>
                                        {chartData.numberOfGuests}/{chartData.expectedNumberOfGuests}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Card>
                            <Card.Body>
                                <Chart type="donut" height="295"
                                    series={[chartData.adultGuests, chartData.childGuests]}
                                    options={{
                                        chart: {
                                            id: "adulti_bambini"
                                        },
                                        labels: ["Adulti", "Bambini"],
                                        legend: {
                                            show: true,
                                            position: "bottom",
                                        },
                                        colors: [commonExports.primaryChartColor, commonExports.tertiaryChartColor],
                                        plotOptions: {
                                            pie: {
                                                donut: {
                                                    labels: {

                                                    }
                                                }
                                            }
                                        }
                                    }}></Chart>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Card>
                            <Card.Body>
                                <Chart type="donut" height="295"
                                    series={[chartData.childMenusModded, chartData.childGuests - chartData.childMenusModded]}
                                    options={{
                                        chart: {
                                            id: "bambini_mod"
                                        },
                                        labels: ["Menu Bimbi Modificati", "Menu Bimbi NON Modificati"],
                                        legend: {
                                            show: true,
                                            position: "bottom",
                                        },
                                        colors: [commonExports.alternateChartColor, commonExports.tertiaryChartColor],
                                        plotOptions: {
                                            pie: {
                                                donut: {
                                                    labels: {

                                                    }
                                                }
                                            }
                                        }
                                    }}></Chart>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} >
                        <Card>
                            <Card.Body>
                                <Chart type="donut" height="295"
                                    series={[chartData.guestsWithAllergies, chartData.numberOfGuests - chartData.guestsWithAllergies]}
                                    options={{
                                        chart: {
                                            id: "allergie"
                                        },
                                        labels: ["Con Allergie", "Senza Allegie"],
                                        legend: {
                                            show: true,
                                            position: "bottom",
                                        },
                                        colors: [commonExports.dangerChartColor, commonExports.primaryChartColor],
                                        plotOptions: {
                                            pie: {
                                                donut: {
                                                    labels: {

                                                    }
                                                }
                                            }
                                        }
                                    }}></Chart>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xs={12} md={6}>
                        <Card>
                            <Card.Body>
                                <Chart type="donut" height="295"
                                    series={[chartData.guestsWithPreferences, chartData.numberOfGuests - chartData.guestsWithPreferences]}
                                    options={{
                                        chart: {
                                            id: "preferenze"
                                        },
                                        labels: ["Con Preferenze", "Senza Preferenze"],
                                        legend: {
                                            show: true,
                                            position: "bottom",
                                        },
                                        colors: [commonExports.warningChartColor, commonExports.primaryChartColor],
                                        plotOptions: {
                                            pie: {
                                                donut: {
                                                    labels: {

                                                    }
                                                }
                                            }
                                        }
                                    }}></Chart>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                <Divider style={{ "marginTop": "16px" }}></Divider>


                <Table height={420} data={listData}
                    autoHeight={true}
                    affixHorizontalScrollbar
                >
                    <Column flexGrow={1} minWidth={150}>
                        <HeaderCell>Nome</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column flexGrow={1} minWidth={150}>
                        <HeaderCell>Cognome</HeaderCell>
                        <Cell dataKey="surname" />
                    </Column>
                    <Column flexGrow={1} minWidth={150}>
                        <HeaderCell>Menu</HeaderCell>
                        <MenuCell dataKey="age" />
                    </Column>
                    <Column flexGrow={1} minWidth={150} >
                        <HeaderCell>Data Registrazione</HeaderCell>
                        <DateCell dataKey="creationDate" />
                    </Column>
                    <Column flexGrow={1} minWidth={150} >
                        <HeaderCell>&nbsp;</HeaderCell>
                        <ActionCell dataKey="id" />
                    </Column>

                </Table>

            </Container>
        </>
    );
}

export default AdminDashboard;